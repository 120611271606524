import {useContext, useEffect, useState} from 'react';
import {getInputPartial} from 'titan-ads/lib/utils';
import {InputsContext} from '../../contexts/AdInputs';
import {GroupID} from '../../types';

interface TrackerURLsProps {
  groupId: GroupID;
}

export function TrackerURLs({groupId}: TrackerURLsProps) {
  const [newUrl, setNewUrl] = useState<string>('');
  const [trackerUrls, setTrackerUrls] = useState<string[]>([]);
  const {handleEditInput} = useContext(InputsContext);

  useEffect(() => {
    const trackerPartial = getInputPartial('quora', 'Tracker URLs');
    handleEditInput(groupId, trackerPartial, trackerUrls);
  }, [trackerUrls]);

  const urlAlreadyExists = trackerUrls.includes(newUrl);

  return (
    <>
      <label>Tracker URLs</label>
      {trackerUrls?.map((url) => (
        <div>
          <span>{url}</span>
          <button
            onClick={() => {
              setTrackerUrls(
                trackerUrls.filter((urlToCheck) => urlToCheck !== url),
              );
            }}
          >
            Delete
          </button>
        </div>
      ))}
      <input
        onChange={(e) => setNewUrl(e.target.value)}
        placeholder="Tracker URL..."
        type="url"
        value={newUrl}
      />
      <button
        onClick={() => {
          setTrackerUrls((oldUrls) => [...oldUrls, newUrl]);
          setNewUrl('');
        }}
        disabled={urlAlreadyExists || !newUrl}
      >
        Add
      </button>
    </>
  );
}
