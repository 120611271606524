import {useContext, useEffect, useState} from 'react';
import {GroupID} from '../../types';
import {InputsContext} from '../../contexts/AdInputs';
import styles from './TimeWindow.scss.json';
import inputStyles from '../AdInput.scss.json';
import './TimeWindow.scss';

interface TimeWindowProps {
  groupId: GroupID;
}

export function TimeWindow({groupId}: TimeWindowProps) {
  const [targetingWindow, setTargetingWindow] = useState<number>(30);
  const {inputGroups} = useContext(InputsContext);

  const {adSet} = inputGroups[groupId];

  useEffect(() => {
    adSet.retargetingWindow = targetingWindow;
    console.log(adSet);
  }, [targetingWindow]);

  return (
    <div
      className={`${inputStyles.Dropdown} ${styles.timeWindow}`}
      title={`Target users who viewed these questions in the last ${targetingWindow} days (between 1-30)`}
    >
      <input
        onChange={(e) => {
          const newValue = e.target.valueAsNumber;

          setTargetingWindow(newValue);
        }}
        type="number"
        max="30"
        min="1"
        step="1"
        value={targetingWindow}
      />
      <span>Days</span>
    </div>
  );
}
