import {AdInputPartial, InputValues, Platform} from 'titan-ads/lib/types';
import {getInputsByPlatform, getPartialKey} from 'titan-ads/lib/utils';
import {IAdInputProps} from '../types';
import {AdInput} from './AdInput';
import './InputGroup.scss';
import style from './InputGroup.scss.json';

interface InternalVariables
  extends Omit<IAdInputProps, 'className' | 'id' | 'inputData' | 'disabled'> {
  creatingAdSet?: boolean;
  creatingCampaign?: boolean;
  initialValues?: InputValues;
  platform?: Platform;
}

export function InternalVariables({
  creatingAdSet = false,
  creatingCampaign = false,
  initialValues,
  platform = 'quora',
  ...inputVariables
}: InternalVariables) {
  const inputOptions = getInputsByPlatform(platform);

  return (
    <>
      <div className={style.subGrid}>
        {inputOptions.map((partial: AdInputPartial, i) => {
          if (!partial.internal) return <></>;

          const belongsToPreexistingEntity =
            (partial.column === 'adset' && !creatingAdSet) ||
            (partial.column === 'campaign' && !creatingCampaign);

          let initialValue;
          if (initialValues) {
            initialValue = initialValues[getPartialKey(partial)];
          }

          return (
            <AdInput
              {...inputVariables}
              disabled={belongsToPreexistingEntity}
              key={`${platform}-${i}`}
              className={style[partial.column]}
              id={getPartialKey(partial)}
              inputData={partial}
              initialValue={initialValue}
            />
          );
        })}
      </div>
    </>
  );
}
