import {PropsWithChildren, createContext, useState, useTransition} from 'react';
import {IDashboardContext} from '../types';
import {BrandAccount} from 'titan-ads/lib/shapes/TitanShapes';

export const DashboardContext = createContext<IDashboardContext | null>(null);

export function ContextProvider({children}: PropsWithChildren) {
  const [accountsLoading, startAccountTransition] = useTransition();
  const [adSetsLoading, startAdSetsTransition] = useTransition();
  const [campaignsLoading, startCampaignsTransition] = useTransition();

  const [curAccount, setCurAccount] = useState<BrandAccount>();
  const [masterUrl, setMasterUrl] = useState<string>('');
  const [landingPageName, setLandingPageName] = useState<string>('');

  return (
    <DashboardContext.Provider
      value={{
        // useState()
        curAccount,
        setCurAccount,

        landingPageName,
        setLandingPageName,

        masterUrl,
        setMasterUrl,

        // useTransition()
        accountsLoading,
        adSetsLoading,
        campaignsLoading,
        startAccountTransition,
        startAdSetsTransition,
        startCampaignsTransition,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}
