import dayjs from 'dayjs';
import {Dispatch, MutableRefObject, useEffect, useState} from 'react';
import VideoThumbnail from 'react-video-thumbnail';
import '../pages/Media.scss';
import styles from '../pages/Media.scss.json';
import {Spinner} from './Spinner';
import {MediaGalleryAsset} from '../types';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

interface MediaUploadGalleryProps {
  galleryImages: MediaGalleryAsset[];
  isMediaSelected: any;
  isSelected: any[];
  disableUploadMore: boolean;
  brandAccount: string;
  multiSelect?: boolean;
  fileInputRef: MutableRefObject<HTMLElement>;
  onMediaReady: (assets: MediaGalleryAsset[]) => void;
  browseMedia: Dispatch<boolean>;
}

export default function MediaUploadGallery({
  galleryImages,
  isMediaSelected,
  isSelected,
  disableUploadMore,
  brandAccount,
  multiSelect = true,
  fileInputRef,
  onMediaReady,
  browseMedia,
}: MediaUploadGalleryProps) {
  const [selectedImage, setSelectedImage] = useState([]);
  const [linkCopied, setLinkCopied] = useState(-1);

  useEffect(() => {
    if (isMediaSelected) {
      setSelectedImage(isMediaSelected);
    }
  }, [isSelected]);

  const handleButtonClick = () => {
    browseMedia(false);
    fileInputRef.current.click();
  };
  function formatDateWithDayjs(isoDateStr) {
    function ordinal(number) {
      const suffixes = ['th', 'st', 'nd', 'rd'];
      const v = number % 100;
      return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    }

    const date = dayjs(isoDateStr);

    // Formatting the date and time
    const formattedDate = `${ordinal(date.date())} ${date.format(
      'MMMM YYYY, HH:mm A',
    )}`;

    return formattedDate;
  }

  const copyLink = (image, index) => {
    if (image) {
      navigator.clipboard
        .writeText(image.url)
        .then(() => {
          console.log('Link copied to clipboard!');
          setLinkCopied(index);
          // You can provide feedback to the user that the link has been copied
        })
        .catch((error) => {
          setLinkCopied(index);
          console.error('Failed to copy link: ', error);
          // Handle errors, such as permissions or unsupported browser
        });
    } else {
      alert('Please select image first');
    }
  };

  const handleImageSelection = (e, choosenImage) => {
    const isChecked = e.target.checked;
    if (multiSelect) {
      if (isChecked) {
        // Add the selected image to the array of selected images
        setSelectedImage((prevSelectedImages) => [
          ...prevSelectedImages,
          choosenImage,
        ]);
      } else {
        // Remove the selected image from the array of selected images
        setSelectedImage((prevSelectedImages) =>
          prevSelectedImages.filter((img) => img !== choosenImage),
        );
      }
    } else {
      if (isChecked) {
        // Only allow one image to be selected
        setSelectedImage([choosenImage]);
      } else {
        // Deselect the image
        setSelectedImage([]);
      }
    }
  };

  const handleMedia = () => {
    onMediaReady(selectedImage);
    browseMedia(false);
  };

  function getFileExtension(url) {
    const extension = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
    return extension ? extension[1] : null;
  }

  return (
    <div
      className={`${styles.MediaUploader} ${
        browseMedia ? styles.animationStart : styles.animationStop
      }`}
    >
      <div className={styles.MediaContent}>
        <div className={styles.MediaHeader}>
          <h1>Quora Media for {brandAccount}</h1>
        </div>
        <div className={styles.closeButton}>
          <img
            src={asset('/images/cross.png')}
            style={{cursor: 'pointer'}}
            width="24"
            onClick={() => browseMedia(false)}
          />
        </div>
        <div className={styles.MediaBody}>
          {galleryImages === null ? (
            <div className={styles.mediaLoading}>
              <Spinner />
            </div>
          ) : galleryImages.length === 0 ? (
            <div className={styles.mediaLoading}>
              <p style={{color: 'black'}}>No media found</p>
            </div>
          ) : (
            <></>
          )}

          {galleryImages ? (
            galleryImages.map(
              (img, index) =>
                img.data.url !== undefined && (
                  <div key={index} className={styles.MediaList}>
                    <div className={styles.MediaSubContent}>
                      <div className={styles.MediaPicture}>
                        <input
                          type="checkbox"
                          id={`select-${img.data.dateCreated}`}
                          onChange={(e) => handleImageSelection(e, img)}
                          checked={selectedImage.includes(img)}
                        />
                        {img.data.usageInfo == 'video' ? (
                          <VideoThumbnail
                            videoUrl={img.data.contentUrl}
                            // thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                          />
                        ) : (
                          <img src={img.data.contentUrl} alt={'thumbnail'} />
                        )}
                      </div>
                      <div className={styles.MediaLabel}>
                        <div className={styles.MediaFile}>
                          <p>
                            {img.data.contentUrl
                              .split('/')
                              .pop()
                              .replace(/\.[^/.]+$/, '')}
                          </p>
                          <p className={styles.typeCheck}>
                            {img.data.usageInfo}
                          </p>
                        </div>
                        <p className={styles.MediaMime}>
                          MIME:
                          {img.data.usageInfo === 'image' || 'logo'
                            ? ' image/' + getFileExtension(img.data.contentUrl)
                            : ' video/' + getFileExtension(img.data.contentUrl)}
                        </p>
                        <p className={styles.MediaMime}>
                          Uploaded: {formatDateWithDayjs(img.data.dateCreated)}
                        </p>
                      </div>
                    </div>
                    <button onClick={() => copyLink(img.data, index)}>
                      {linkCopied === index ? (
                        <p className={styles.linkCopied}>Link Copied</p>
                      ) : (
                        <img src={asset('/images/link.png')} width="20" />
                      )}
                    </button>
                  </div>
                ),
            )
          ) : (
            <Spinner />
          )}
        </div>
      </div>
      <div className={styles.MediaFooter}>
        {/* {disableUploadMore ? null : (
          <button onClick={handleButtonClick}>Upload More +</button>
        )} */}
        <button
          disabled={!selectedImage.length}
          className={styles.buttonInsert}
          onClick={handleMedia}
        >
          Insert Media
        </button>
      </div>
    </div>
  );
}
