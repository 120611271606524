import {useContext} from 'react';
import {InputsContext} from '../../contexts/AdInputs';
import {GroupID} from '../../types';
import '../AdInput.scss';
import inputStyles from '../AdInput.scss.json';
import '../InputGroup.scss';
import styles from '../InputGroup.scss.json';

interface BroadTargetingProps {
  groupId: GroupID;

  disabled?: boolean;
}

export function BroadTargeting({
  disabled = false,
  groupId,
}: BroadTargetingProps) {
  const {inputGroups} = useContext(InputsContext);

  const {adSet} = inputGroups[groupId];

  const className = `${styles.adset} ${inputStyles.Dropdown} ${inputStyles.checkbox}`;

  return (
    <div className={className}>
      <label>Turn on auto-targeting?</label>
      <input
        disabled={disabled}
        type="checkbox"
        onChange={(e) => {
          adSet.isAutoTargeting = e.target.checked;
        }}
      />
    </div>
  );
}
