import {Server} from 'lincd-server-utils/lib/utils/Server';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {DeliveryPayload} from 'titan-ads/lib/backend';
import {AdCard} from 'titan-ads/lib/components/AdCard';
import {AdSetCard} from 'titan-ads/lib/components/AdSetCard';
import {CampaignCard} from 'titan-ads/lib/components/CampaignCard';
import {packageName} from 'titan-ads/lib/package';
import {Ad,AdSet,Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {Delivering} from 'titan-ads/lib/shapes/Delivering';
import {QUORA_ENTITY_TYPES, QuoraDeliveryState} from 'titan-ads/lib/types';
import {plural} from 'titan-ads/lib/utils';
import './SearchResults.scss';
import * as styles from './SearchResults.scss.json';
import {linkedSetComponent} from '../package';

type ResultContextType = {
  selectedResult: ResultType;
  isDelivering: any;
  // onResultClick: (e, a: ResultType) => void;
  handleAdState?: (state: boolean, entityType: ResultType) => void;
  handleLoading?: (value: boolean) => void;
  isLoading?: boolean;
  updateIndicatorStatus?: (accountId: number, entityResult: ResultType) => void;
};

type ResultType = Ad | AdSet | Campaign;
const ResultContext = createContext<ResultContextType>(null);

export const SearchResults = ({
  searchResults,
}: {
  searchResults: ShapeSet<ResultType>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDelivering, setIsDelivering] = useState<{
    [key: string]: QuoraDeliveryState;
  }>({});

  const ads: ShapeSet<Ad> = searchResults.filter((r) => r instanceof Ad) as any;
  const adSets: ShapeSet<AdSet> = searchResults.filter(
    (r) => r instanceof AdSet,
  ) as any;
  const campaigns: ShapeSet<Campaign> = searchResults.filter(
    (r) => r instanceof Campaign,
  ) as any;

  const categorisedResults = [ads, adSets, campaigns];

  const handleAdState = useCallback(
    (state: boolean, selectedEntity: ResultType) => {
      let quoraAccountId;
      if (selectedEntity instanceof Ad) {
        quoraAccountId = selectedEntity.adSet.campaign.account.quoraAccount?.id;
      } else if (selectedEntity instanceof AdSet) {
        quoraAccountId = selectedEntity.campaign.account.quoraAccount?.id;
      } else if (selectedEntity instanceof Campaign) {
        quoraAccountId = selectedEntity.account.quoraAccount?.id;
      }
      if (state) {
        Server.call(packageName, 'unpauseEntity', selectedEntity).then(() => {
          updateIndicatorStatus(quoraAccountId, selectedEntity);
        });
      } else {
        Server.call(packageName, 'pauseEntity', selectedEntity).then(() => {
          updateIndicatorStatus(quoraAccountId, selectedEntity);
        });
      }
      setIsLoading(false);
    },
    [],
  );

  // useEffect(() => {
  //   if (searchResults) {
  //     searchResults.forEach((result) => {
  //       if (result.quoraId) {
  //         setIsDelivering((prev) => ({
  //           ...prev,
  //           [result.name]: result.delivering?.status,
  //         }));
  //       }
  //     });
  //   }
  // }, [searchResults]);

  /**
   * @param accountId
   * @todo maybe look into improving this and keeping less things in state?
   * @method batch unpause ads and update the delivery status (quora review)
   */

  const batchAdsUnpause = useCallback((accountId: number, ads: Ad[]) => {
    setIsLoading(true);

    // when the unpause campaign, pause all
    Server.call(packageName, 'unpauseAds', ads).then(() => {
      ads.forEach((ad) => {
        updateIndicatorStatus(accountId, ad);
      });
    });
  }, []);

  /**
   * @param accountId
   * @todo maybe look into improving this and keeping less things in state?
   */

  const updateIndicatorStatus = useCallback(
    (accountId: number, entityResult: ResultType) => {
      setIsDelivering((prev) => ({
        ...prev,
        [entityResult?.name]: 'loading',
      }));
      //TODO: to review after refacting id. Id is now avaiable in every type, but maybe not set for every tpe?
      //if name is needed, need to detect when its needed in a different way.
      // const query = 'id' in entityResult ? entityResult?.id : entityResult.name;
      const query = entityResult?.id || entityResult.identifier || entityResult?.name;

      let entityType: QUORA_ENTITY_TYPES = 'ad';
      if (entityResult instanceof AdSet) entityType = 'ad_set';
      if (entityResult instanceof Campaign) entityType = 'campaign';
      Server.call(
        packageName,
        'isDelivering',
        query,
        entityType,
        accountId,
      ).then((r: DeliveryPayload) => {
        if(!r) {
          console.warn('No response from isDelivering');
          return;
        }
        if (r?.error) {
          // TODO
          console.error(r.error);
        }

        setIsDelivering((prev) => ({
          ...prev,
          [entityResult.name]: r.deliveryStatus,
        }));
        const delivering = Delivering.getFromURI(
          entityResult.uri + '/delivery',
        );
        delivering.status = r.deliveryStatus;
        delivering.save();

        if (r.entityId) {
          entityResult.quoraId = r.entityId;
        }
        entityResult.delivering = delivering;
        entityResult.save();
      });
    },
    [],
  );

  // const resultOnClick = (e, result: ResultType) => {
  //   setSelectedResult(result);
  //   // // Only smoothly scroll if the panel is already open
  //   // if (selectedResult) {
  //   //   e.currentTarget.scrollIntoView({
  //   //     behavior: 'smooth',
  //   //   });
  //   // } else {
  //   //   e.currentTarget.scrollIntoView();
  //   // }
  // };

  return (
    <>
      {searchResults.size > 0 && (
        <div className={styles.resultsSummary}>
          {categorisedResults.map((r, i) => {
            if (r.size === 0) return null;
            return (
              <span key={i}>
                {r.size} {r.first().constructor.name.toLowerCase()}
                {plural(r.size)}
              </span>
            );
          })}
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.results}>
          {/* Changing to linkedComponents caused the render to  */}
          <ResultContext.Provider
            value={{
              selectedResult: null,
              isDelivering,
              // onResultClick: resultOnClick,
              handleAdState,
              handleLoading: setIsLoading,
              isLoading,
              updateIndicatorStatus,
            }}
          >
            <AdResults of={ads} />
            <AdSetResults of={adSets} />
            <CampaignResults of={campaigns} />
          </ResultContext.Provider>
        </div>
        {/* {
          // Make the panel disappear if the search changes
          selectedResult && searchResults.has(selectedResult) && (
            <div className={styles.resultsPanelContainer}>
              <FaRegWindowClose
                onClick={() => {
                  setSelectedResult(null);
                }}
              />
              <ResultPanel
                updateIndicatorStatus={updateIndicatorStatus}
                entity={selectedResult}
              />
            </div>
          )
        } */}
      </div>
    </>
  );
};

const AdResults = linkedSetComponent<Ad>(
  (Ad as any).requestForEachInSet((ad) => () => AdCard.of(ad)),
  ({sources, getLinkedData}) => {
    const {
      isDelivering,
      handleAdState,
      handleLoading,
      isLoading,
      updateIndicatorStatus,
    } = useContext(ResultContext);
    return (
      <>
        {sources.map((a) => {
          const Card = getLinkedData(a) as any;
          return (
            <>
              <Card
                deliveryState={isDelivering[a.name]}
                of={a}
                onUpdateStatus={(account: number) => updateIndicatorStatus(account, a)}
                loadingState={handleLoading}
                loadingEnd={isLoading}
                handleAdState={(value: boolean) => {
                  // onResultClick(null, a);
                  handleAdState(value, a);
                }}
              />
            </>
          );
        })}
      </>
    );
  },
);

const AdSetResults = linkedSetComponent<AdSet>(
  (AdSet as any).requestForEachInSet((adSet) => () => AdSetCard.of(adSet)),
  ({sources, getLinkedData}) => {
    const {
      isDelivering,
      handleAdState,
      handleLoading,
      isLoading,
      updateIndicatorStatus,
    } = useContext(ResultContext);
    return (
      <>
        {sources.map((a) => {
          const Card = getLinkedData(a) as any;
          return (
            <>
              <Card
                deliveryState={isDelivering[a.name]}
                of={a}
                onClick={(account: number) => updateIndicatorStatus(account, a)}
                loadingState={handleLoading}
                loadingEnd={!isLoading}
                handleAdState={(value: boolean) => {
                  // onResultClick(null, a);
                  handleAdState(value, a);
                }}
              />
            </>
          );
        })}
      </>
    );
  },
);

const CampaignResults = linkedSetComponent<Campaign>(
  (Campaign as any).requestForEachInSet(
    (campaign) => () => CampaignCard.of(campaign),
  ),
  ({sources, getLinkedData}) => {
    const {
      isDelivering,
      handleAdState,
      handleLoading,
      isLoading,
      updateIndicatorStatus,
    } = useContext(ResultContext);

    return (
      <>
        {sources.map((c) => {
          const Card = getLinkedData(c) as any;
          return (
            <>
              <Card
                deliveryState={isDelivering[c.name]}
                of={c}
                onClick={(account: number) => updateIndicatorStatus(account, c)}
                loadingState={handleLoading}
                loadingEnd={isLoading}
                handleAdState={(value: boolean) => {
                  // onResultClick(null, c);
                  handleAdState(value, c);
                }}
              />
            </>
          );
        })}
      </>
    );
  },
);
