export const localFetch = (endpoint: string, body?: BodyInit) => {
  console.assert(endpoint.startsWith('/'));

  return fetch(`${process.env.SITE_ROOT}${endpoint}`, {
    method: 'post',
    body,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const getCollisionSafeId = () =>
  localFetch('/get-collision-safe-id').then(async (res) => await res.json());
