import {useContext} from 'react';
import {generateAdName} from 'titan-ads/lib/utils';
import {InputsContext as AdInputs} from '../contexts/AdInputs';
import {GroupID} from '../types';
import './RefreshQuoraData.scss';

const CHARACTER_LIMIT = 255;

export function CharacterLimit({groupId}: {groupId: GroupID}) {
  const {inputGroups} = useContext(AdInputs);

  const {adSet, campaign, inputs, platform} = inputGroups[groupId];

  if (!(adSet && campaign)) {
    return <></>;
  }

  const adName = generateAdName(inputs, platform, campaign, adSet);
  const adNameAfterTrimming = generateAdName(inputs, platform, campaign, adSet, CHARACTER_LIMIT);
  const adIdLength = 20;

  // Ad name, plus the "adID09aZ-"
  const curCharCount = adName.length + adIdLength + 1;

  return (
    <>
      {curCharCount / CHARACTER_LIMIT > 0.8 && (
        <>
          {curCharCount}/{CHARACTER_LIMIT}
        </>
      )}
      {curCharCount / CHARACTER_LIMIT > 1 && (
        <>
          {' '}
          - Don&apos;t worry, this will get trimmed down to{' '}
          {adNameAfterTrimming.length}
        </>
      )}
    </>
  );
}
