import {
  ChangeEvent,
  FocusEvent,
  FocusEventHandler,
  useContext,
  useState,
} from 'react';
import {debounce} from '../../utils';
import inputStyles from '../AdInput.scss.json';
import './SearchInput.scss';
import styles from './SearchInput.scss.json';
import {ThemeContext} from '../../contexts/Theme';

interface SearchResultsProps {
  isLoading: boolean;
  onChooseResult: (result: any) => void;
  results: any[];

  disabled?: boolean;
  processResult?: (result: any) => string;
}

interface MultiSelectProps extends SearchResultsProps {
  doSearch: (query: string) => void;
  label: string;
}

export function SearchInput({
  disabled = false,
  doSearch,
  isLoading,
  label,
  onChooseResult,
  processResult = (result) => `${result}`,
  results,
}: MultiSelectProps) {
  const [previousSearch, setPreviousSearch] = useState<string>('');

  const doDebouncedSearch = debounce(doSearch);

  /**
   * This is here to prevent the search from being executed when the user
   * re-focuses the input and the value hasn't changed
   */
  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    setPreviousSearch(e.target.value);
  };

  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value !== previousSearch) {
      executeSearch(e);
    }
  };

  const executeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;

    if (!query) return;
    doDebouncedSearch(query);
  };

  return (
    <div className={`${styles.searchContainer} ${inputStyles.Dropdown}`}>
      <label>{label}</label>
      <input
        disabled={disabled}
        className={styles.searchbar}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={executeSearch}
        type="search"
      />
      <SearchResults
        isLoading={isLoading}
        onChooseResult={onChooseResult}
        processResult={processResult}
        results={results}
      />
    </div>
  );
}

export const searchClasses = {
  container: `${styles.searchContainer} ${inputStyles.Dropdown}`,
  searchbar: styles.searchbar,
};

export function SearchResults({
  disabled = false,
  isLoading,
  onChooseResult,
  processResult,
  results,
}: SearchResultsProps) {
  const {isLight} = useContext(ThemeContext);
  return (
    <div
      tabIndex={0}
      className={isLight ? styles.optionContainer : styles.optionContainerDark}
    >
      {!isLoading && results.length > 0 ? (
        results.map((result, i) => {
          const text = processResult(result);
          return (
            <SearchResult
              key={i}
              onClick={() => onChooseResult(result)}
              text={text ?? result.uri?.split(/#\/?/).pop()}
            />
          );
        })
      ) : (
        <SearchResult
          selectable={false}
          text={isLoading ? 'Loading...' : 'No results'}
        />
      )}
    </div>
  );
}

interface SearchResultProps {
  text: string;

  onClick?: () => void;
  selectable?: boolean;
}

function SearchResult({
  onClick = () => {},
  selectable = true,
  text,
}: SearchResultProps) {
  const a11yChooseOption = (e: any, onClick: () => void) => {
    if (e.key === 'Enter' && selectable) {
      onClick();
    }
  };

  const {isLight} = useContext(ThemeContext);

  return (
    <span
      className={`${isLight ? styles.option : styles.optionDark} ${
        selectable ? (isLight ? styles.selectable : styles.selectableDark) : ''
      }`}
      onKeyDown={(e) => a11yChooseOption(e, onClick)}
      onClick={() => onClick()}
      tabIndex={selectable ? 0 : -1}
    >
      {text}
    </span>
  );
}
