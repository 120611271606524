import {PropsWithChildren, useContext, useEffect, useState} from 'react';
import {Location} from 'titan-ads/lib/shapes/Location';
import {AdInputPartial} from 'titan-ads/lib/types';
import {getInputPartial, getPartialKey} from 'titan-ads/lib/utils';
import {InputsContext} from '../../contexts/AdInputs';
import {DashboardContext} from '../../contexts/Dashboard';
import styles from '../InputGroup.scss.json';
import {SearchInput} from './SearchInput';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

interface LocationsProps extends PropsWithChildren {
  disabled?: boolean;
  groupId: number;
  label: 'Excluded Locations' | 'Included Locations';
  partial: AdInputPartial;
}

export function Locations({
  children,
  disabled = false,
  groupId,
  label,
  partial,
}: LocationsProps) {
  const {handleEditInput, inputGroups} = useContext(InputsContext);
  const {curAccount} = useContext(DashboardContext);

  const {adSet, inputs} = inputGroups[groupId];

  const initialChosen =
    label === 'Excluded Locations'
      ? new Set(adSet.excludedGeolocations)
      : new Set(adSet.geolocations);

  const [chosen, setChosen] = useState<Set<Location>>(initialChosen);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Location[]>([]);
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);

  useEffect(() => {
    const newValue = Array.from(chosen)
      .map((location) => location.id)
      .join(',');
    const oldValue = inputs[getPartialKey(partial)];
    if (newValue !== oldValue) handleEditInput(groupId, partial, newValue);
  }, [chosen]);

  const doSearch = (query: string) => {
    setIsLoading(true);

    const ignoreIds = Array.from(chosen).map((location) => location.id);

    // You can't include and exclude the same location if it's already
    // been chosen - therefore we want to exclude it from the quora query
    const otherLocationsLabel =
      label === 'Excluded Locations'
        ? 'Included Locations'
        : 'Excluded Locations';
    const otherLocationsIndex = getInputPartial('quora', otherLocationsLabel);
    const otherLocations =
      inputGroups[groupId].inputs[getPartialKey(otherLocationsIndex)];

    if (otherLocations)
      ignoreIds.push(...otherLocations?.toString().split(','));

    curAccount.quoraAccount
      .getLocations(query, ignoreIds)
      .then((res) => {
        setResults(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChooseResult = (result: Location) => {
    setResults((prev) => prev.filter((r) => r.name !== result.name));

    const updatedChosen = new Set(chosen);
    updatedChosen.add(result);
    setChosen(updatedChosen);

    if (label === 'Included Locations') adSet.geolocations.add(result);
    else adSet.excludedGeolocations.add(result);
  };

  const removeLocation = (location: Location) => {
    const updatedChosen = new Set(chosen);
    updatedChosen.delete(location);
    setChosen(updatedChosen);

    if (label === 'Included Locations') adSet.geolocations.delete(location);
    else adSet.excludedGeolocations.delete(location);
  };

  const processResult = (result: Location) =>
    `(${result.geotype}) ${result.name}`;

  const searchLabel = `${chosen.size || ''} ${label.slice(0, -1)}${
    chosen.size === 1 ? '' : 's'
  }`;

  return (
    <div className={styles.adset}>
      <div>
        {Array.from(chosen).map((location) => (
          <div
            key={location.id}
            style={{
              display: 'flex',
              marginTop: '20px',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '20px',
              height: '15px',
            }}
          >
            <p>{location.name}</p>
            <img
              onClick={() => removeLocation(location)}
              src={asset("/images/cross.png")}
              style={{width: '15px', height: '15px', marginLeft: '5px'}}
              alt="remove"
            />
          </div>
        ))}
      </div>
      <SearchInput
        disabled={disabled}
        doSearch={doSearch}
        isLoading={isLoading}
        label={searchLabel}
        onChooseResult={onChooseResult}
        processResult={processResult}
        results={results}
      />
      {children}
    </div>
  );
}
