import '../pages/Media.scss';
import '../components/RefreshQuoraData.scss';
import styles2 from '../components/RefreshQuoraData.scss.json';
import styles from '../pages/Media.scss.json';
import {Spinner} from './Spinner';
import {MIMEType, MIMETypes} from '../types';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';
import VideoThumbnail from 'react-video-thumbnail';

interface FileUploaderProps {
  handleInput;
  uploadedImage;
  handleTypeChange;
  selectedType: MIMEType;
  handleImageVideoSelect;
  fileInputRef;
  resetInput;
  mediaType;
  handleFileUpload;
  fileImage;
  isLoading;
  uploadStatus;
  statusVisibility;
  isQuickLaunched;
  nameError;
  fileError;
  browseMedia;
}

export default function FileUploader({
  handleInput,
  uploadedImage,
  handleTypeChange,
  isQuickLaunched,
  selectedType,
  handleImageVideoSelect,
  mediaType,
  fileInputRef,
  resetInput,
  handleFileUpload,
  fileImage,
  isLoading,
  uploadStatus,
  statusVisibility,
  nameError,
  fileError,
  browseMedia,
}: FileUploaderProps) {
  return (
    <>
      <div>
        {!isQuickLaunched && (
          <>
            <div className={styles.input}>
              <label>Name</label>
              <input type="text" onChange={handleInput} />
            </div>
            {nameError && (
              <p className={styles.validationError}>
                <img
                  src={asset('/images/mark.png')}
                  width={24}
                  className={styles.imgError}
                />
                {nameError}
              </p>
            )}
            <div className={styles.input}>
              <label>Type</label>
              <select
                value={selectedType}
                className={styles2.dropdown}
                onChange={handleTypeChange}
              >
                <option value={MIMETypes[0]}>Image</option>
                <option value={MIMETypes[1]}>Logo</option>
                <option value={MIMETypes[2]}>Video</option>
              </select>
            </div>
            <div className={styles.inputFile}>
              <input
                type="file"
                ref={fileInputRef}
                accept={
                  selectedType === 'video'
                    ? '.mov, .mp4'
                    : 'image/jpeg, image/png'
                }
                onChange={(e) => {
                  handleImageVideoSelect(e);
                }}
                onClick={resetInput}
              />
            </div>
            {fileError && (
              <p className={styles.validationError}>
                <img
                  src={asset('/images/mark.png')}
                  width={24}
                  className={styles.imgError}
                />
                {fileError}
              </p>
            )}
            <button
              onClick={() => {
                handleFileUpload(fileImage);
              }}
            >
              {!isLoading ? 'Submit' : 'In progress...'}
            </button>
          </>
        )}
        {/* Turn off media type selected so can select logo upload on all ads creation */}
        {/* {mediaType === 'image_ad' || mediaType === 'video_ad' ? ( */}
        <div className={styles.input}>
          <button onClick={() => browseMedia(true)}>Browse Media</button>
        </div>
        {/* ) : null} */}
      </div>
      <div className={styles.isLoading}>
        {isLoading && (
          <>
            {!uploadStatus.cypressIsUpload ? (
              <Spinner active={true} />
            ) : (
              <>
                {uploadStatus.isUploadSuccess ? (
                  <img src={asset('/images/checkmark.gif')} width="50" />
                ) : (
                  <img src={asset('/images/mark.png')} width="50" />
                )}
              </>
            )}
            {statusVisibility.cdnUpload && (
              <p>
                {uploadStatus.cdnUpload
                  ? 'Upload to CDN completed.'
                  : 'Uploading to CDN...'}
              </p>
            )}
            {statusVisibility.localUpload && (
              <p>
                {uploadStatus.localUpload
                  ? 'Saved to local storage.'
                  : 'Saving to local storage...'}
              </p>
            )}
            {statusVisibility.cypressUpload && (
              <p>
                {uploadStatus.cypressIsUpload && uploadStatus.isUploadSuccess
                  ? 'Uploading to Quora completed.'
                  : uploadStatus.cypressIsUpload &&
                    !uploadStatus.isUploadSuccess
                  ? 'Upload failed'
                  : 'Uploading to Quora...'}
              </p>
            )}
          </>
        )}
      </div>
      <>
        {!statusVisibility.cypressUpload &&
          uploadStatus.cypressIsUpload &&
          uploadStatus.isUploadSuccess && (
            <div className={styles.uploadedImages}>
              <h5>File uploaded:</h5>
              {selectedType === 'video' ? (
                <VideoThumbnail videoUrl={uploadedImage} />
              ) : (
                <img src={uploadedImage} />
              )}
            </div>
          )}
      </>
    </>
  );
}
export function BrowseMedia({browseMedia}) {
  return (
    <div className={styles.input}>
      <button onClick={() => browseMedia(true)}>Browse Media</button>
    </div>
  );
}
