import {ChangeEvent,useContext,useEffect,useRef,useState} from 'react';
import {AdInputPartial} from 'titan-ads/lib/types';
import {InputsContext} from '../../contexts/AdInputs';
import {GroupID} from '../../types';
import {TimeWindow} from './TimeWindow';
import styles from '../InputGroup.scss.json';
import '../InputGroup.scss';
import inputStyles from '../AdInput.scss.json';
import '../AdInput.scss';
import {InputField} from 'lincd-form/lib/shapes/InputField';

interface KeywordsProps {
  groupId: GroupID;
  partial: AdInputPartial;
  includeTimeWindow?: boolean;
}

export function Keywords({
  groupId,
  partial,
  includeTimeWindow = false,
}: KeywordsProps) {

  const {handleEditInput, inputGroups} = useContext(InputsContext);
  const {adSet} = inputGroups[groupId];
  const [keywords, setKeywords] = useState<string[]>(adSet.keywords);
  const [updateIndex, setUpdateIndex] = useState<number>(0);
  const input = useRef<any>();
  useEffect(() => {
    handleEditInput(groupId, partial, keywords.join(', '));
    adSet.keywords = keywords;
    console.log(adSet.keywords);
  }, [keywords]);

  //this makes sure that state is updated when graph data updates outside of this component
  useEffect(() => {
    setKeywords([...adSet.keywords]);
    //setUpdateIndex(updateIndex+1);
    if(input.current.value !== adSet.keywords.join(", ")) {
      input.current.value = adSet.keywords.join(", ");
    }
  }, [adSet.keywords.join(", ")]);


  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeywords(
      e.target.value
        // Quora uses commas to separate keywords
        .split(',')
        // We want to eliminate any whitespace
        .map((k) => k.trim())
        // And remove any empty strings
        .filter((k) => k),
    );
  };

  const className = `${styles.adset} ${inputStyles.Dropdown} ${styles.required}`;

  return (
    <>
      <div className={className}>
        {includeTimeWindow && <TimeWindow groupId={groupId} />}
        <label>Keywords (comma-separated)</label>
        <input type="text" ref={input} onChange={onChange} defaultValue={keywords.join(", ")} className={''} />
      </div>
    </>
  );
}
