import {Header} from './Header';
import './DefaultLayout.scss';
import style from './DefaultLayout.scss.json';
import {useContext} from 'react';
import {ThemeContext} from '../contexts/Theme';
import {Link} from 'react-router-dom';

export function DefaultLayout({children}) {
  const {isLight} = useContext(ThemeContext);
  return (
    <main id="app-main" className={isLight ? style.main : style.mainDark}>
      <Header />
      {children}
      <p
        style={{textAlign: 'center', marginTop: '4rem', paddingBottom: '6rem'}}
      >
        If you're running into any issues, please email{' '}
        <Link
          to="mailto:service@semantu.com"
          style={{textDecoration: 'underline'}}
        >
          <code>service@semantu.com</code>
        </Link>
      </p>
    </main>
  );
}
