import {Server} from 'lincd-server-utils/lib/utils/Server';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {useState} from 'react';
import {Ad} from 'titan-ads/lib/shapes/TitanShapes';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {SearchResults} from '../components/SearchResults';
import {DefaultLayout} from '../layout/DefaultLayout';
import {packageName} from '../package';
import {debounce} from '../utils';
import {TitanProvider} from 'titan-ads/lib/backend';

/**
 *
 * @todo Implement specific search (by ads, ad sets, or campaigns) - would
 *  speed things up, rather than searching by all categories
 */
export default function Search() {
  const [searchResults, setSearchResults] = useState<
    ShapeSet<Ad | AdSet | Campaign>
  >(new ShapeSet());
  const [isSearching, setIsSearching] = useState(null);
  const [query, setQuery] = useState('');

  const search = debounce((e) => {
    setSearchResults(new ShapeSet());
    setQuery(e.target.value);
    if (e.target.value.length < 3) {
      return;
    }

    setIsSearching(true);
    Server.call(packageName, 'search', e.target.value).then((r) => {
      setSearchResults(r);
      setIsSearching(false);
    });
  });

  const canSearch = query.length >= 3;
  const displayNoResults = isSearching === false && (!searchResults || searchResults.size === 0);

  return (
    <DefaultLayout>
      <h1>Search</h1>
      <div>
        <p>Search for ads, ad sets, and campaigns.</p>
        <input disabled={isSearching} type="text" onChange={search} />
      </div>
      {displayNoResults && canSearch ? (
        <h1>No results found for '{query}'</h1>
      ) : (
        !isSearching && <SearchResults searchResults={searchResults} />
      )}
      {isSearching && <h1>Searching...</h1>}
      {!canSearch && <h1>Enter at least 3 characters to search</h1>}
    </DefaultLayout>
  );
}
