import {IconType} from 'react-icons';
import './PageCard.scss';
import * as styles from './PageCard.scss.json';
import {Link} from 'react-router-dom';

export const PageCard = ({
  route,
  icon,
  label,
}: {
  route: string;
  icon: IconType;
  label: string;
}) => {
  const Icon = icon;
  return (
    <Link className={styles.main} to={route}>
      <div>
        <Icon />
        <h2>{label}</h2>
      </div>
    </Link>
  );
};
