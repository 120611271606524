import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import style from '../pages/Quicklaunch.scss.json';
import {
  AdInputPartial,
  QuoraDeviceAndBrowser,
  QuoraPlacement,
  QUORA_OPT_PLACEMENT,Gender,
} from 'titan-ads/lib/types';
import {InputsContext} from '../contexts/AdInputs';
import style2 from './AdInput.scss.json';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {
  getInputPartial,
  getInputsByPlatform,
  getPartialKeyByTitle,
} from 'titan-ads/lib/utils';

// Define types for the shared props
interface Option {
  label: string;
  value: string | number | QuoraDeviceAndBrowser;
}

interface ConstraintProps {
  handleEditInput: (
    groupId: number,
    inputData: AdInputPartial,
    value: string | number,
    e?: ChangeEvent<HTMLInputElement>,
  ) => void;
  groupId: number;
  inputData: AdInputPartial;
  adSet?: AdSet;
}

// Placement Component
export const Placement: React.FC<ConstraintProps> = ({
  handleEditInput,
  groupId,
  inputData,
  adSet,
}) => {
  const [placement, setPlacement] = useState<string>('All Placements');
  const [specificPlacements, setSpecificPlacements] = useState<string[]>();
  const PlacementConstraint: Option[] = [
    {label: 'All Placements', value: 'All Placements'},
    {label: 'Choose specific placements', value: 'Choose specific placements'},
  ];

  const specificPlacementOption: Option[] = [
    {label: 'Feed', value: 'Feed'},
    {label: 'Questions', value: 'Questions'},
    {label: 'Digest Emails', value: 'Digest Emails'},
  ];

  useEffect(() => {
    setSpecificPlacements([adSet.placement as QuoraPlacement]);
    setPlacement(
      adSet.placement !== 'All Placements'
        ? 'Choose specific placements'
        : 'All Placements',
    );
  }, [adSet.placement]);

  useEffect(() => {
    if (placement === 'All Placements') {
      handleEditInput(groupId, inputData, 'All Placements');
    } else {
      handleEditInput(
        groupId,
        inputData,
        specificPlacements[0] as QuoraPlacement,
      );
    }
  }, [placement, specificPlacements]);

  // useEffect(() => {
  //   const value = specificPlacements[0] as QuoraPlacement;
  //   if (value) {
  //     adSet.placement = value;
  //     setPlacement('Choose specific placements');
  //   }
  // }, [specificPlacements]);

  const onSelectPlacement = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === 'All Placements') {
      setSpecificPlacements([]);
      handleEditInput(groupId, inputData, 'All Placements', e);
    }
    setPlacement(value);
  };

  const onSelectSpecificPlacement = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the selected placement to the state
      setSpecificPlacements([value]);
      handleEditInput(groupId, inputData, value, e);
      adSet.placement = value as QuoraPlacement;
    } else {
      // Remove the deselected placement from the state
      setSpecificPlacements([]);
      handleEditInput(groupId, inputData, '', e);
      adSet.placement = value as QuoraPlacement;
    }
  };

  return (
    <div className={style.constraintOptions}>
      {PlacementConstraint.map((constraint) => (
        <div key={constraint.value}>
          <input
            type="radio"
            id={constraint.value.toString()}
            name="placement"
            value={constraint.value.toString()}
            checked={placement === constraint.value.toString()}
            onChange={onSelectPlacement}
          />
          <label htmlFor={constraint.value.toString()}>
            {constraint.label}
          </label>
        </div>
      ))}
      {placement === 'Choose specific placements' && (
        <div className={style.inputMulti}>
          {specificPlacementOption.map((specificPlacement) => {
            //     specificPlacements.includes(specificPlacement.value.toString()),
            // );
            return (
              <div key={specificPlacement.value} style={{marginLeft: '20px'}}>
                <input
                  type="checkbox"
                  id={`specificPlacement_${specificPlacement.value}`}
                  name="specificPlacement"
                  value={specificPlacement.value.toString()}
                  checked={specificPlacements.includes(
                    specificPlacement.value.toString(),
                  )}
                  onChange={onSelectSpecificPlacement}
                />
                <label htmlFor={`specificPlacement_${specificPlacement.value}`}>
                  {specificPlacement.label}
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

// DeviceAndBrowser Component
export const DeviceAndBrowser: React.FC<ConstraintProps> = ({
  handleEditInput,
  groupId,
  inputData,
  adSet,
}) => {
  const {inputGroups} = useContext(InputsContext);
  const {platform} = inputGroups[groupId];

  const [device, setDevice] = useState<string>('Mobile & Desktop');
  const [specificDevices, setSpecificDevices] = useState<string[]>([]);
  const [specificBrowsers, setSpecificBrowsers] = useState<string[]>([]);

  const [specificGenders, setSpecificGenders] = useState<string[]>(['Female','Male','Unknown']);
  const [genderOption, setGenderOption] = useState<string>('All genders');

  useEffect(() => {
    setSpecificGenders([
      ...(adSet.targetsFemales ? ['Female'] : []),
      ...(adSet.targetsMales ? ['Male'] : []),
      ...(adSet.targetsUnknown ? ['Unknown'] : []),
    ]);
    //if some but not all values are checked, then specific
    //but if all values sare selected , then "All genders" is selected
    setGenderOption(
      (adSet.targetsFemales || adSet.targetsMales || adSet.targetsUnknown) && !(adSet.targetsFemales && adSet.targetsMales && adSet.targetsUnknown)
        ? 'Choose specific genders'
        : 'All genders',
    );
    if (genderOption === 'All genders') {
      adSet.targetsFemales = true;
      adSet.targetsMales = true;
      adSet.targetsUnknown = true;
    }
  }, [adSet.targetsFemales, adSet.targetsMales, adSet.targetsUnknown]);

  useEffect(() => {
    if (adSet.deviceAndBrowser !== 'Mobile & Desktop') {
      if (
        SpecificBrowsers.some(
          (option) => option.value === adSet.deviceAndBrowser,
        )
      ) {
        setDevice('Choose specific device & browsers');
        setSpecificBrowsers([adSet.deviceAndBrowser as QuoraDeviceAndBrowser]);
        setSpecificDevices([]);
      } else {
        setDevice('Choose specific device & browsers');
        setSpecificDevices([adSet.deviceAndBrowser as QuoraDeviceAndBrowser]);
        setSpecificBrowsers([]);
      }
    } else {
      setDevice('Mobile & Desktop');
      setSpecificDevices([]);
      setSpecificBrowsers([]);
    }
  }, [adSet.deviceAndBrowser]);

  const GenderOptions: Option[] = [
    {label: 'All genders', value: 'All genders'},
    {label: 'Choose specific genders', value: 'Choose specific genders'},
  ];

  const GenderConstraints: Option[] = [
    {label: 'Female', value: 'Female'},
    {label: 'Male', value: 'Male'},
    {label: 'Unknown', value: 'Unknown'},
  ];

  const onSelectGenderOption = (e: ChangeEvent<HTMLInputElement>) => {
    type Title = 'Male' | 'Female' | 'Unknown';
    const value = e.target.value;
    const isChecked = e.target.checked;
    setGenderOption(value);
    const inputs = getInputsByPlatform('quora');
    const maleInput = inputs.find((input) => input.title === 'Male');
    const femaleInput = inputs.find((input) => input.title === 'Female');
    const unknownInput = inputs.find((input) => input.title === 'Unknown');

    // if (value === 'All genders') {
    //always set all values to true, so that the user can UNselect the ones they dont want
    adSet.targetsMales = true;
    handleEditInput(groupId, maleInput, true.toString());
    adSet.targetsFemales = true;
    handleEditInput(groupId, femaleInput, true.toString());
    adSet.targetsUnknown = true;
    handleEditInput(groupId, unknownInput, true.toString());
    // } else {
    //   adSet.targetsMales = true;
    //   handleEditInput(groupId, maleInput, true.toString());
    //   adSet.targetsFemales = true;
    //   handleEditInput(groupId, femaleInput, true.toString());
    //   adSet.targetsUnknown = true;
    //   handleEditInput(groupId, unknownInput, true.toString());
    // }
  };

  const onSelectSpecificGender = (e: ChangeEvent<HTMLInputElement>) => {
    type Title = 'Male' | 'Female' | 'Unknown';
    const value = e.target.value;
    const isChecked = e.target.checked;
    const newSpecificGenders = [...specificGenders];
    let inputPartial = {} as AdInputPartial;

    if (value === 'Male') {
      adSet.targetsMales = isChecked;
      inputPartial = getInputPartial(platform, 'Male');
    } else if (value === 'Female') {
      adSet.targetsFemales = isChecked;
      inputPartial = getInputPartial(platform, 'Female');
    } else if (value === 'Unknown') {
      adSet.targetsUnknown = isChecked;
      inputPartial = getInputPartial(platform, 'Unknown');
    }

    handleEditInput(groupId, inputPartial, isChecked.toString(), e);
    setGenderOption('Choose specific genders');

    if (isChecked) {
      newSpecificGenders.push(value);
      setSpecificGenders(newSpecificGenders);
    } else {
      const index = specificGenders.indexOf(value);
      if (index > -1) {
        newSpecificGenders.splice(index, 1);
        setSpecificGenders(newSpecificGenders);
      }
    }

    if (newSpecificGenders.length === 0) {
      adSet.targetsFemales = true;
      adSet.targetsMales = true;
      adSet.targetsUnknown = true;
      setGenderOption('All genders');
    }
  };

  const DeviceConstraints: Option[] = [
    {label: 'Mobile & Desktop', value: 'Mobile & Desktop'},
    {
      label: 'Choose specific device & browsers',
      value: 'Choose specific device & browsers',
    },
  ];

  const SpecificDevices: Option[] = [
    {label: 'All Mobile Devices', value: 'All Mobile Devices'},
    {label: 'iOS', value: 'iOS'},
    {label: 'Android', value: 'Android'},
    {label: 'Others', value: 'Others'},
  ];

  const SpecificBrowsers: Option[] = [
    {label: 'All Desktop Browsers', value: 'All Desktop Browsers'},
    {label: 'Chrome', value: 'Chrome'},
    {label: 'Safari', value: 'Safari'},
    {label: 'Firefox', value: 'Firefox'},
    {label: 'Others Browsers', value: 'Others Browsers'},
  ];

  const onSelectDevice = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    setDevice(value);
    if (value === 'Mobile & Desktop' && isChecked) {
      setSpecificBrowsers([]);
      setSpecificDevices([]);
      handleEditInput(groupId, inputData, value, e);
    }
  };

  const onSelectSpecificDevice = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setSpecificDevices([value]);
      setSpecificBrowsers([]); // Reset specific browsers when selecting device
      handleEditInput(groupId, inputData, value, e);
      // Update adSet.deviceAndBrowser
      adSet.deviceAndBrowser = value as QuoraDeviceAndBrowser;
    }
  };

  const onSelectSpecificBrowser = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setSpecificBrowsers([value]);
      setSpecificDevices([]); // Reset specific devices when selecting browser
      handleEditInput(groupId, inputData, value, e);
      // Update adSet.deviceAndBrowser
      adSet.deviceAndBrowser = value as QuoraDeviceAndBrowser;
    }
  };

  return (
    <div className={style.constraintOptions}>
      {DeviceConstraints.map((constraint) => {
        console.log(
          constraint.value +
            ' checked: ' +
            specificDevices.includes(constraint.value.toString()),
        );
        return (
          <div key={constraint.value}>
            <input
              type="radio"
              id={constraint.value.toString()}
              name="device"
              value={constraint.value.toString()}
              checked={device === constraint.value.toString()}
              onChange={onSelectDevice}
            />
            <label htmlFor={constraint.value.toString()}>
              {constraint.label}
            </label>
          </div>
        );
      })}
      {device === 'Choose specific device & browsers' && (
        <div className={style.inputMulti}>
          <div className={style.flexColumn}>
            <div style={{marginTop: '5px'}}>
              {SpecificDevices.map((specificDevice) => {
                console.log(
                  specificDevice.value +
                    ' checked: ' +
                    specificDevices.includes(specificDevice.value.toString()),
                );
                return (
                  <div key={specificDevice.value} style={{marginLeft: '20px'}}>
                    <input
                      type="checkbox"
                      id={`specificDevice_${specificDevice.value}`}
                      name="specificDevice"
                      value={specificDevice.value.toString()}
                      checked={specificDevices.includes(
                        specificDevice.value.toString(),
                      )}
                      onChange={onSelectSpecificDevice}
                    />
                    <label htmlFor={`specificDevice_${specificDevice.value}`}>
                      {specificDevice.label}
                    </label>
                  </div>
                );
              })}
            </div>
            <div style={{marginTop: '5px'}}>
              {SpecificBrowsers.map((specificBrowser) => {
                console.log(
                  specificBrowser.value +
                    ' checked: ' +
                    specificBrowsers.includes(specificBrowser.value.toString()),
                );

                return (
                  <div key={specificBrowser.value} style={{marginLeft: '20px'}}>
                    <input
                      type="checkbox"
                      id={`specificBrowser_${specificBrowser.value}`}
                      name="specificBrowser"
                      value={specificBrowser.value.toString()}
                      checked={specificBrowsers.includes(
                        specificBrowser.value.toString(),
                      )}
                      onChange={onSelectSpecificBrowser}
                    />
                    <label htmlFor={`specificBrowser_${specificBrowser.value}`}>
                      {specificBrowser.label}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div className={`${style2.Dropdown} ${style2.required}`}>
        <label>Gender</label>
      </div>
      {GenderOptions.map((option) => {
        return (
          <div key={option.value}>
            <input
              type="radio"
              id={option.value.toString()}
              name="genderOption"
              value={option.value.toString()}
              checked={genderOption === option.value.toString()}
              onChange={onSelectGenderOption}
            />
            <label htmlFor={option.value.toString()}>{option.label}</label>
          </div>
        );
      })}
      {genderOption === 'Choose specific genders' && (
        <div className={style.inputMulti}>
          {GenderConstraints.map((constraint) => {
            return (
              <div key={constraint.value} style={{marginLeft: '20px'}}>
                <input
                  type="checkbox"
                  id={`specificGender_${constraint.value}`}
                  name="specificGender"
                  value={constraint.value.toString()}
                  checked={specificGenders.includes(
                    constraint.value.toString(),
                  )}
                  onChange={onSelectSpecificGender}
                />
                <label htmlFor={`specificGender_${constraint.value}`}>
                  {constraint.label}
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
