import {MouseEvent, Suspense, useContext, useEffect, useState} from 'react';
import {AdSet,BrandAccount,Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {
  AdInputPartial,
  QuoraAdDelivery,
  QuoraDeviceAndBrowser,
  QuoraPlacement,
  QuoraTargetingType,
} from 'titan-ads/lib/types';
import {
  getInputsByPlatform,
  getPartialKey,
  getPartialKeyByTitle,
} from 'titan-ads/lib/utils';
import {InputsContext} from '../contexts/AdInputs';
import {DashboardContext} from '../contexts/Dashboard';
import {ThemeContext} from '../contexts/Theme';
import '../pages/Dashboard.scss';
import dashboardStyles from '../pages/Dashboard.scss.json';
import {useSuspense} from '../utils';
import {AdInput} from './AdInput';
import {Answers} from './AdSet/Answers';
import {Audiences} from './AdSet/Audiences';
import {BroadTargeting} from './AdSet/Broad';
import {Keywords} from './AdSet/Keywords';
import {Locations} from './AdSet/Locations';
import {Questions} from './AdSet/Questions';
import {Topics} from './AdSet/Topics';
import './InputGroup.scss';
import style from './ManageTemplates.scss.json';
import styles from './InputGroup.scss.json';
import {Spinner} from './Spinner';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {Literal} from 'lincd/lib/models';
import {titanAds} from 'titan-ads/lib/ontologies/titan-ads';
import {Topic} from 'titan-ads/lib/shapes/Topic';
import {LinkedStorage} from 'lincd/lib/utils/LinkedStorage';

interface TemplateGroupProps {
  refreshList: () => void;
  templatesRes?: {read(): ShapeSet<AdSet>};
}

// Regular "Ad Creation" groups display groups of index 0 and greater
// and src/components/InitialReport.tsx uses GROUP_ID = -1
const GROUP_ID = -2;

export function TemplateGroup({...props}: TemplateGroupProps) {
  // const [accountsResource, _] = useState<{read(): any}>(
  //   useSuspense(BrandAccount.loadAll()),
  // );
  const [error, setError] = useState(false);
  const {curAccount, setCurAccount} = useContext(DashboardContext);
  const [curAccountState, setCurAccountState] = useState(null);
  useEffect(() => {
    // Remove the current account if an error occurs
    if (error) setCurAccount(null);
    if (error) setCurAccountState(null);
  }, [error]);

  useEffect(() => {
    if (!curAccount) {
      BrandAccount.loadAll()
        .then(() => {
          console.debug('Finding default brand account...');
          const defaultAccount = BrandAccount.getLocalInstances().first();
          // BrandAccount.getLocalInstances().find(
          //   (brand) => brand.shortName.toLowerCase() === 'smtu',
          // );
          if (defaultAccount) {
            console.info('Found default brand account!');
            setCurAccount(defaultAccount);
            setCurAccountState(defaultAccount);
          } else {
            console.warn('No default brand account found (smtu)');
            setCurAccount(null);
            setCurAccountState(null);
          }
        })
        .catch((err) => {
          console.error('Error loading brand accounts:', err);
          setError(true);
        });
    }
  }, [curAccount, setCurAccount]);

  // // If any child components throw errors (e.g. fetching from Quora)
  const ErrorHandler = () => {
    useEffect(() => {
      setError(true);
    }, []);

    return <Spinner />;
  };

  return (
    <div>
      <hr />
      <h2>Add a new template</h2>
      <Suspense fallback={<ErrorHandler />}>
        {curAccount && <TemplateInputs {...props} curAccount={curAccount} />}
      </Suspense>
    </div>
  );
}

interface TemplateInputsProps extends TemplateGroupProps {
  curAccount: BrandAccount;
}

function TemplateInputs({curAccount, templatesRes}: TemplateInputsProps) {
  const [templateName, setTemplateName] = useState<string>('');
  const [savingTemplate, setSavingTemplate] = useState(false);
  const [_, forceUpdate] = useState(0);

  const {
    handleAddGroup,
    handleRemoveGroup,
    handleEditInput,
    handleMarkAsCreatingAdSet,
    handleMarkAsCreatingCampaign,
    inputGroups,
  } = useContext(InputsContext);
  const {isLight} = useContext(ThemeContext);

  useEffect(() => {
    setTemplateName('');

    const campaign = new Campaign(curAccount,true);
    const adSet = AdSet.create({});
    //by default all genders is selected, and thus all these are true
    adSet.targetsUnknown=true;
    adSet.targetsMales = true;
    adSet.targetsFemales = true;

    try {
      handleRemoveGroup(GROUP_ID);
    } catch (e) {
      console.error(e);
    }
    //start with all genders selected
    handleAddGroup(GROUP_ID, 'quora', {
      [getPartialKeyByTitle('quora', 'Male')]:true,
      [getPartialKeyByTitle('quora', 'Female')]:true,
      [getPartialKeyByTitle('quora', 'Unknown')]:true,
    }, campaign, adSet);

    handleMarkAsCreatingAdSet(GROUP_ID, true);
    handleMarkAsCreatingCampaign(GROUP_ID);
    //there seems to be some race condition, the updated inputs do not always trigger a rerender with the new values
    setTimeout(() => {
      forceUpdate(_ + Math.random());
    }, 500);
  }, []);

  const inputPartials = getInputsByPlatform('quora');

  const adSetInputs = inputPartials.filter((partial) => {
    return (
      !partial.internal &&
      partial.column == 'adset' &&
      partial.title !== 'Date Launched'
    );
  });
  const campaignInputs = inputPartials.filter((partial) => {
    return (
      !partial.internal &&
      partial.column === 'campaign' &&
      partial.title !== 'Date Launched'
    );
  });

  const onSaveTemplate = async (e: MouseEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!templateName) return alert('Please enter the template name');

    const byRequired = (p: AdInputPartial) => p.required;

    const requiredCampaignInputs = campaignInputs.filter(byRequired);

    for (const partial of requiredCampaignInputs) {
      const {shapePath} = partial;
      if (shapePath instanceof Array) {
        console.log(partial.title, 'N/A - ARRAY');
      } else if (campaign.getValue(shapePath) === undefined) {
        console.log(campaign);
        return alert(`Please fill in the campaign's "${partial.title}"`);
      }
    }
    const requiredAdSetInputs = adSetInputs.filter(byRequired);
    for (const partial of requiredAdSetInputs) {
      const {shapePath} = partial;
      if (shapePath instanceof Array) {
        console.log(partial.title, 'N/A - ARRAY');
      } else if (adSet.getValue(shapePath) === undefined) {
        console.log(adSet);
        return alert(`Please fill in the adset's "${partial.title}"`);
      }
    }

    setSavingTemplate(true);

    try {
      await AdSet.createTemplate(
        templateName,
        campaign as Campaign,
        curAccount,
        adSet as AdSet
      );


    } catch (e) {
      setSavingTemplate(false);
      return alert(e.message);
    }
    setSavingTemplate(false);

    window.location.reload();
  };


  //turned this off, we now continue to render and expect the inputGroups to become defined soon enough
  // if (
  //   !inputGroups[GROUP_ID] ||
  //   !inputGroups[GROUP_ID].adSet ||
  //   !inputGroups[GROUP_ID].campaign
  // )
  //   return <Spinner />;

  //added empty default values to prevent strange race condition where the form never renders
  const {adSet, campaign} = inputGroups[GROUP_ID] || {
    adSet: {getValue: () => undefined, campaign: undefined},
    campaign: {getValue: () => undefined},
  };
  const targetingType = adSet.targetingType;

  return (
    <div className={isLight ? styles.row : styles.rowDark}>
      <Suspense fallback={<Spinner />}>
        <CopyFrom templatesRes={templatesRes} adSetInputs={adSetInputs} campaignInputs={campaignInputs} />
      </Suspense>
      <input
        style={{width: 'fit-content'}}
        className={dashboardStyles.buttons}
        disabled={savingTemplate}
        type="button"
        onClick={onSaveTemplate}
        value={savingTemplate ? 'Creating..' : 'Create template'}
      />
      <div style={{display: 'block'}}>
        <label>Template name </label>
        <input
          style={{marginLeft: '5px'}}
          type="text"
          onChange={(e) => setTemplateName(e.target.value)}
          value={templateName}
        />
      </div>
      <div
        className={styles.subGrid}
        style={{alignItems: 'flex-start', display: 'flex'}}
      >
        <fieldset style={{width: '50%'}}>
          <legend>Campaign</legend>
          {campaignInputs.map((p) => {
            // let value: InputType;
            // // TODO: Support nested shapePaths
            // if (p.shapePath instanceof NamedNode) {
            //   value = campaign.getValue(p.shapePath);
            // }

            let className = styles.campaign;
            if (p.required) {
              className += ` ${styles.required}`;
            }

            const key = `${getPartialKey(p)}`;

            const conversionObjective =
              inputGroups[GROUP_ID]?.inputs[
                getPartialKeyByTitle('quora', 'Conversion Objective')
              ];

            if (
              p.title === 'Conversion Event' &&
              conversionObjective !== 'Conversions'
            )
              return null;

            return inputGroups[GROUP_ID] ? (
              <AdInput
                className={className}
                groupId={GROUP_ID}
                id={getPartialKey(p)}
                inputData={p}
                key={key}
              />
            ) : (
              <Spinner key={key} />
            );
          })}
        </fieldset>
        <fieldset style={{width: '50%'}}>
          <legend>AdSet</legend>
          {adSetInputs.map((p) => {
            let className = styles.adset;
            if (p.required) {
              className += ` ${styles.required}`;
            }

            const key = `${getPartialKey(p)}`;

            if (!inputGroups[GROUP_ID]) {
              return <Spinner key={p.title} />;
            }
            let initialValue;
            if (p.title === 'Placement') {
              initialValue = adSet.placement;

              // "Ads within a campaign where an objective is set to App
              // Install, Video Views, or Lead Generation are not eligible to
              // appear in Quora Digest emails" - Quora Dashboard
              switch (true) {
                case campaign.conversionObjective === 'App installs':
                case campaign.conversionObjective === 'Video views':
                // case campaign.conversionObjective === 'Lead generation':
                // "Ads within an ad set where delivery is being optimized for
                // conversions or impressions are not eligible to appear in
                // Quora Digest emails" - Quora Dashboard
                case adSet.adDelivery === 'Cost per 1000 impressions':
                case adSet.adDelivery === 'Optimise for conversions':
                  p.options = p.options.filter(
                    (option: QuoraPlacement) => option !== 'Digest Emails',
                  );
                default:
                  break;
              }
            } else if (p.title === 'Device & Browser') {
              initialValue = adSet.deviceAndBrowser;

              switch (true) {
                case campaign.conversionObjective === 'App installs':
                  p.options = p.options.filter(
                    (option: QuoraDeviceAndBrowser) =>
                      !option.includes('Desktop'),
                  );
                default:
                  break;
              }
            }

            if (p.title === 'Ad Delivery') {
              // Cost per 1000 impressions is an option for all conversion
              // objectives, and Cost per click is available for all objectives
              // bar Video Views
              const deliveryOptions: QuoraAdDelivery[] = [
                'Cost per click',
                'Cost per 1000 impressions',
              ];

              switch (campaign.conversionObjective) {
                case 'App installs':
                case 'Awareness':
                case 'Conversions':
                  deliveryOptions.push('Optimise for conversions');
                  break;
                // case 'Lead generation':
                //   deliveryOptions.push('Optimise for leads');
                //   break;
                case 'Video views':
                  deliveryOptions.shift(); // Remove 'Cost per click' for video views
                  deliveryOptions.push('Optimise for video views');
                default: // Either traffic, or an unknown objective
                  break;
              }

              p.options = deliveryOptions;

              if (adSet.placement === 'Digest Emails') {
                p.options = p.options.filter(
                  (option: QuoraAdDelivery) =>
                    option !== 'Optimise for conversions' &&
                    option !== 'Cost per 1000 impressions',
                );
              }
            }

            if (p.title === 'Targeting Type') {
              p.options = p.options.filter((option: QuoraTargetingType) => {
                return option !== 'Audience';
              });
            }

            // "Ads within an ad set targeting questions or keywords are not
            // eligible to appear in Quora Digest emails" - Quora Dashboard
            if (
              p.title === 'Targeting Type' &&
              adSet.placement === 'Digest Emails'
            ) {
              p.options = p.options.filter(
                (option: QuoraTargetingType) =>
                  option !== 'Contextual Questions' &&
                  option !== 'Contextual Keywords',
              );
            }

            // All components which interact with Quora's API
            switch (true) {
              case p.title === 'Excluded Locations':
              case p.title === 'Included Locations':
                return (
                  <Locations
                    key={key}
                    label={p.title}
                    groupId={GROUP_ID}
                    partial={p}
                  />
                );
              case p.title === 'Targeting Description': {
                if (targetingType === 'Audience')
                  return <Audiences key={key} className={styles.adset} />;
                else if (targetingType === 'Broad')
                  return <BroadTargeting key={key} groupId={GROUP_ID} />;
                else if (targetingType === 'Behavioral Question history') {
                  return (
                    <Questions
                      key={key}
                      groupId={GROUP_ID}
                      partial={p}
                      includeTimeWindow={true}
                    />
                  );
                } else if (targetingType === 'Contextual Questions') {
                  return <Questions key={key} groupId={GROUP_ID} partial={p} />;
                } else if (targetingType === 'Behavioral Keyword history') {
                  return (
                    <Keywords
                      key={key}
                      groupId={GROUP_ID}
                      partial={p}
                      includeTimeWindow={true}
                    />
                  );
                } else if (targetingType === 'Contextual Keywords') {
                  return <Keywords key={key} groupId={GROUP_ID} partial={p} />;
                }

                switch (true) {
                  case targetingType === 'Behavioral Interests':
                  case targetingType === 'Contextual Topics':
                    return <Topics key={key} groupId={GROUP_ID} partial={p} />;
                }
                if (targetingType === 'Behavioral Answer history') {
                  return (
                    <Answers
                      key={key}
                      groupId={GROUP_ID}
                      partial={p}
                      includeTimeWindow={true}
                    />
                  );
                }
              }
            }

            return inputGroups[GROUP_ID] ? (
              <AdInput
                className={className}
                groupId={GROUP_ID}
                id={getPartialKey(p)}
                inputData={p}
                initialValue={initialValue}
                key={key}
              />
            ) : (
              <Spinner key={key} />
            );
          })}
        </fieldset>
      </div>
    </div>
  );
}

const CopyFrom = ({templatesRes,adSetInputs,campaignInputs}) => {
  const {
    handleEditInput,
    inputGroups,
  } = useContext(InputsContext);

  const templates: ShapeSet<AdSet> = templatesRes.read();

  const copyFrom = async (event) => {
    const uri = event.target.value;
    if (!uri) return;
    //ask the user with a native browser dialoge if they are sure
    const result = window.confirm(
      'Are you sure you want to copy values from this template and overwrite your current values?',
    );
    if (!result) return;

    const template = templates.find((template) => template.uri === uri);
    // console.log(template);
    // console.log(inputPartials);
    // console.log(inputGroups);
    const updateValue = (sourceShape, targetShape, shapePath, partial) => {
      //try to find the property shape back based onthe shapePath
      const pShape = targetShape.nodeShape
        .getPropertyShapes()
        .find((p) => p.path === shapePath);

      let accessorName;
      if (pShape) {
        accessorName = pShape.label;
      } else {
        //try to access the get method based on the name of the property (because some methods use multiple propertyies that are not defined in the shapePath)
        accessorName = shapePath.uri.split(/#|\//g).pop();
      }

      let value;
      try {
        //try to get the current value with the get accessor
        value = sourceShape[accessorName];
      } catch (err) {
        console.log(
          'get ' +
          accessorName +
          ' not defined in ' +
          sourceShape.constructor.name,
        );
      }
      //if not, try to access the value directly
      if (!value) {
        if (sourceShape.getQuads(shapePath).size <= 1) {
          value = sourceShape.getOne(shapePath);
          if (value instanceof Literal) {
            value = value.value;
          }
        } else {
          //TODO: if it comes here at all, what do we do with the values
          value = sourceShape.getAll(shapePath);
        }
      }

      //handle special case for topics
      if (partial.title === 'Targeting Description') {
        switch ((sourceShape as AdSet).targetingType) {
          case 'Contextual Keywords':
            const keywords = sourceShape
              .getAll(titanAds.keywords)
              .map((v) => v.value);
            targetShape.keywords = keywords;
            value = keywords.join(', ');
            handleEditInput(GROUP_ID, partial, value);
          case 'Contextual Topics':
          case 'Behavioral Interests':
            const topics = sourceShape.getAllAs(titanAds.hasTopics, Topic);
            //clear old topics
            targetShape.topics.forEach((topic) => {
              targetShape.topics.delete(topic);
            });
            //add each new topic
            topics.forEach((topic) => {
              targetShape.topics.add(topic);
            });
            value = topics.map((t) => t.name).join(',');
            handleEditInput(GROUP_ID, partial, value);
        }
      }

      if (value) {
        if (pShape) {
          try {
            targetShape[pShape.label] = value;
          } catch (err) {
            console.warn(err);
          }
        }
        handleEditInput(GROUP_ID, partial, value);
      }
    };
    let sourceShape: any = template;
    let targetShape: any = inputGroups[GROUP_ID].adSet;
    for (const partial of adSetInputs) {
      const {shapePath} = partial;
      if (shapePath) {
        if (shapePath instanceof Array) {
          console.log(partial.title, 'N/A - ARRAY');
        } else {
          updateValue(sourceShape, targetShape, shapePath, partial);
        }
      } else {
        console.log('No shapepath: ', partial);
      }
    }
    //custom adset input fields
    // updateValue(sourceShape,targetShape,titanAds.hasTopics);

    //update all campaign inputs
    sourceShape = template.campaign;
    targetShape = inputGroups[GROUP_ID].campaign;
    for (const partial of campaignInputs) {
      const {shapePath} = partial;
      if (shapePath) {
        if (shapePath instanceof Array) {
          console.log(partial.title, 'N/A - ARRAY');
        } else {
          updateValue(sourceShape, targetShape, shapePath, partial);
        }
      }
    }
    // {
    //   "campaign__Conversion Objective": "Traffic",
    //   "adset__Placement": "Feed",
    //   "adset__Device & Browser": "Mobile & Desktop",
    //   "adset__Targeting Type": "Contextual Topics",
    //   "adset__Targeting Description": "",
    //   "adset__Included Locations": "",
    //   "adset__Excluded Locations": "",
    //   "adset__Male": false,
    //   "adset__Female": false,
    //   "adset__Unknown": false,
    //   "adset__Ad Delivery": "Cost per click"
    // }
  };

  return <div>
    Copy from :{' '}
    <select onChange={copyFrom}>
      <option value={''}>---select a template----</option>
      {templates.map((template) => {
        return (
          <option key={template.uri} value={template.uri}>
            {template.name}
          </option>
        );
      })}
    </select>
  </div>
}