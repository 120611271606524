import {rdf} from 'lincd-rdf/lib/ontologies/rdf';
import {NamedNode} from 'lincd/lib/models';
import {LinkedStorage} from 'lincd/lib/utils/LinkedStorage';
import {useState} from 'react';
import style from '../App.scss.json';
import '../App.scss';
import {BrandAccount} from 'titan-ads/lib/shapes/TitanShapes';

export function CreateNewAccount({forceRender}: {forceRender})
{
  const [longName,setLongName] = useState<string>('');
  const [shortName,setShortName] = useState<string>('');
  const [offer,setOffer] = useState<string>('');

  const resetFields = () => {
    setLongName('');
    setShortName('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      longName.length > shortName.length &&
      longName &&
      shortName &&
      offer
    )
    {
      const uri = process.env.DATA_ROOT + '/' + shortName;
      const accountNode = NamedNode.getOrCreate(uri);

      if (accountNode.properties.size > 0)
      {
        alert(
          'An account already exists with this short name.\n\nPlease try again.',
        );
        return;
      }

      accountNode.overwrite(rdf.type,BrandAccount.shape.targetClass);

      const newAccount = new BrandAccount(accountNode);
      newAccount.name = longName;
      newAccount.offer = offer;
      newAccount.shortName = shortName;
      newAccount.save();
      LinkedStorage.promiseUpdated().then(() => {
        alert('Created new account');
        window.location.reload();
      });
    }
    else
    {
      alert(
        'All fields must be filled and account name must be longer than the short name',
      );
    }
  };
  return (
    <>
      <form
        className={style.createAccount}
      >
        <div className={style.formrow}>
          <label htmlFor="name">Publishing Brand Name</label>
          <input
            name="name"
            type="text"
            onChange={(e) => setLongName(e.target.value)}
            value={longName}
          />
        </div>
        <br />
        <div className={style.formrow}>
          <label htmlFor="short-name">Short Name</label>
          <input
            name="short-name"
            type="text"
            onChange={(e) => setShortName(e.target.value)}
            value={shortName}
          />
        </div>
        <br />
        <div className={style.formrow}>
          <label htmlFor="short-name">Account/offer name</label>
          <input
            name="offer"
            type="text"
            onChange={(e) => setOffer(e.target.value)}
            value={offer}
          />
          <label><input
            name="offer-multi"
            type="checkbox"
            onChange={(e) => {
              e.target.checked ? setOffer('Multi-offer') : setOffer('');
            }}
            checked={offer === 'Multi-offer'}
          /> multi offer</label>
        </div>
      </form>
      <br />
      {/*<input*/}
      {/*  name="reset"*/}
      {/*  type="button"*/}
      {/*  value="Reset"*/}
      {/*  onClick={() => {*/}
      {/*    resetFields();*/}
      {/*  }}*/}
      {/*/>*/}
      <input name="create" type="button" onClick={onSubmit} value="Create Account" />
    </>
  );
}
