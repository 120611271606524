import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';
import {TemplateList} from 'titan-ads/lib/components/TemplateList';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {ThemeContext} from '../contexts/Theme';
import '../pages/Quicklaunch.scss';
import style from '../pages/Quicklaunch.scss.json';
import {useSuspense} from '../utils';
import {Spinner} from './Spinner';
import { NodeSet } from 'lincd/lib/collections/NodeSet';

const PlacementConstraints = [
  'Email Templates',
  'All Other Placements',
  // 'Only',
] as const;

const DeviceConstraints = [
  'No constraint',
  'Mobile & Desktop',
  'Choose specific device & browsers',
  // 'Only',
] as const;
const SpecificDevices = [
  'All Mobile Devices',
  'iOS',
  'Android',
  'Others',
] as const;
const SpecificBrowsers = [
  'All Desktop Browsers',
  'Chrome',
  'Safari',
  'Firefox',
  'Others',
] as const;
const GenderOptions = [
  'No constraint',
  'All genders only',
  'Choose specific genders',
  // 'Only',
] as const;

const GenderConstraints = [
  'Female AdSets only',
  'Male AdSets only',
  'Unknown AdSets only',
] as const;

type DeviceConstraint = (typeof DeviceConstraints)[number];
type SpecificDevice = (typeof SpecificDevices)[number];
type SpecificBrowser = (typeof SpecificBrowsers)[number];
type GenderOption = (typeof GenderOptions)[number];
type GenderConstraint = (typeof GenderConstraints)[number];
type PlacementConstraint = (typeof PlacementConstraints)[number];

interface ConstraintProps {
  constraintAdSets: ShapeSet<AdSet>;
  setConstraintAdSets: Dispatch<SetStateAction<ShapeSet<AdSet>>>;
}


export default function ConstraintComponent({...props}: ConstraintProps) {
  const [placement, setPlacement] = useState<PlacementConstraint>('All Other Placements');
  const [device, setDevice] = useState<DeviceConstraint>('No constraint');
  const [specificDevices, setSpecificDevices] = useState<SpecificDevice[]>([]);
  const [specificBrowsers, setSpecificBrowsers] = useState<SpecificBrowser[]>(
    [],
  );
  const [genderOption, setGenderOption] = useState<GenderOption>('No constraint');
  const [genders, setGenders] = useState<GenderConstraint[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [templates, setTemplates] = useState({read: () => new ShapeSet<AdSet>()});

  useEffect(() => {
    setTemplates(useSuspense(AdSet.loadAllTemplates()));
  },[])
  const forceRefreshList = () => setRefresh(!refresh);

  const onSelectPlacement = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as PlacementConstraint;
    setPlacement(value);
  };

  const onSelectDevice = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as DeviceConstraint;
    setDevice(value);
    setSpecificDevices([]);
    setSpecificBrowsers([]);
  };

  const onSelectSpecificDevice = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as SpecificDevice;
    setSpecificDevices((prev) =>
      // value === 'All Mobile Devices'
      //   ? prev.includes(value)
      //     ? []
      //     : ['All Mobile Devices', 'iOS', 'Android', 'Others']
      //   :
    prev.includes(value)
        ? prev.filter((device) => device !== value)
        : [...prev, value],
    );
  };

  const onSelectSpecificBrowser = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as SpecificBrowser;
    setSpecificBrowsers((prev) =>
      // value === 'All Desktop Browsers'
      //   ? prev.includes(value)
      //     ? []
      //     : ['All Desktop Browsers', 'Chrome', 'Safari', 'Firefox', 'Others']
      //   :
        prev.includes(value)
        ? prev.filter((browser) => browser !== value)
        : [...prev, value],
    );
  };

  const onSelectGenderOption = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as GenderOption;
    setGenderOption(value);
    setGenders([]);
  };

  const onSelectGender = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as GenderConstraint;
    setGenders((prev) =>
      prev.includes(value)
        ? prev.filter((gender) => gender !== value)
        : [...prev, value],
    );
  };

  // useEffect(() => {
  //   setTemplates(useSuspense(AdSet.loadAllTemplates()));
  // }, [
  //   // device,
  //   // specificDevices,
  //   // specificBrowsers,
  //   // genderOption,
  //   // genders,
  //   // refresh,
  // ]);

  return (
    <div className={style.constraintOptions}>
      <div>
        <div className={style.inputMulti}>
          <label>Placement</label>
          {PlacementConstraints.map((constraint) => (
            <div key={constraint}>
              <label><input
                type="radio"
                id={constraint}
                name="placement"
                value={constraint}
                checked={placement === constraint}
                onChange={onSelectPlacement}
              />
                {constraint}</label>
            </div>
          ))}
        </div>
        <div className={style.inputMulti}>
          <label>Device & Browser</label>
          {DeviceConstraints.map((constraint) => (
            <div key={constraint}>
              <label><input
                type="radio"
                id={constraint}
                name="device"
                value={constraint}
                checked={device === constraint}
                onChange={onSelectDevice}
              />
                {constraint}</label>
            </div>
          ))}
          {device === 'Choose specific device & browsers' && (
            <div className={style.inputMulti}>
              <span>Each selected field will include all templates with that device/browser setting</span>
              <div className={style.flexColumn}>
                <div>
                  {SpecificDevices.map((specificDevice) => (
                    <div key={specificDevice} style={{marginLeft: '20px'}}>
                      <label>
                        <input
                          type="checkbox"
                          name="specificDevice"
                          value={specificDevice}
                          checked={specificDevices.includes(specificDevice)}
                          onChange={onSelectSpecificDevice}
                        />
                        {specificDevice}
                      </label>
                    </div>
                  ))}
                </div>
                <div>
                  {SpecificBrowsers.map((specificBrowser) => (
                    <div key={specificBrowser} style={{marginLeft: '20px'}}>
                      <label>
                        <input
                          type="checkbox"
                          name="specificBrowser"
                          value={specificBrowser}
                          checked={specificBrowsers.includes(specificBrowser)}
                          onChange={onSelectSpecificBrowser}
                        />
                        {specificBrowser}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={style.inputMulti}>
          <label>Gender</label>
          {GenderOptions.map((option) => (
            <div key={option}>
              <label><input
                type="radio"
                id={option}
                name="genderOption"
                value={option}
                checked={genderOption === option}
                onChange={onSelectGenderOption}
              />
                {option}</label>
            </div>
          ))}
          {genderOption === 'Choose specific genders' && (
            <div className={style.inputMulti}>
              {GenderConstraints.map((constraint) => (
                <div key={constraint} style={{marginLeft: '20px'}}>
                  <label><input
                    type="checkbox"
                    id={constraint}
                    name="gender"
                    value={constraint}
                    checked={genders.includes(constraint)}
                    onChange={onSelectGender}
                  />
                    {constraint}</label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div style={{maxHeight: '65vh',overflowY: 'auto',overflowX: 'hidden'}}>
        <Suspense fallback={<Spinner />}>
          <TemplateFilter
            {...props}
            placement={placement}
            device={device}
            specificDevices={specificDevices}
            specificBrowsers={specificBrowsers}
            genderOption={genderOption}
            genders={genders}
            refreshList={forceRefreshList}
            templateRes={templates}
          />
        </Suspense>
      </div>
    </div>
  );
}

interface TemplateFilterProps extends ConstraintProps
{
  device: DeviceConstraint;
  specificDevices: SpecificDevice[];
  placement: PlacementConstraint;
  specificBrowsers: SpecificBrowser[];
  genderOption: GenderOption;
  genders: GenderConstraint[];
  refreshList: () => void;
  templateRes: {read(): ShapeSet<AdSet>};
}

function TemplateFilter({
                          constraintAdSets,
                          device,
                          placement,
                          specificDevices,
                          specificBrowsers,
                          genderOption,
                          genders,
                          refreshList,
                          setConstraintAdSets,
                          templateRes,
                        }: TemplateFilterProps)
{
  const {isLight} = useContext(ThemeContext);

  const templates = templateRes.read();

  const [unselectedTemplates, setUnselectedTemplates] = useState(new NodeSet());

  const filteredTemplates = templates
    .filter((template) => {
      return !unselectedTemplates.has(template.namedNode);
    })
    .filter((template) => {
      // console.log('tempalte', template);

      // Filter by placement (currently only email is considered as filter)
      if(placement === 'Email Templates' && template.placement !== 'Digest Emails') return false;
      if(placement === 'All Other Placements' && template.placement === 'Digest Emails') return false;

      if (
        genderOption === 'All genders only' &&
        template.targetsFemales &&
        template.targetsMales &&
        template.targetsUnknown
      )
        return true;
      // template.targetsAllGenders = false; // reset the state if unchecked

      if (genders.includes('Female AdSets only'))
      {
        if (!template.targetsMales && template.targetsFemales && !template.targetsUnknown) return true;
        // template.targetsFemales = false; // reset the state if unchecked
      }
      if (genders.includes('Male AdSets only'))
      {
        if (template.targetsMales && !template.targetsFemales && !template.targetsUnknown) return true;
        // template.targetsMales = false; // reset the state if unchecked
      }
      if (genders.includes('Unknown AdSets only')) {
        if (!template.targetsMales && !template.targetsFemales && template.targetsUnknown) return true;
        // template.targetsUnknown = false; // reset the state if unchecked
      }
      if (genderOption === 'No constraint') return true;
      return false;
    })
    .filter((template) => {
      // Filter by device constraints
      if (device === 'No constraint') return true;
      if(device === 'Mobile & Desktop') {
        return template.deviceAndBrowser.toLowerCase().includes('Mobile & Desktop'.toLowerCase());
      }
      if (device === 'Choose specific device & browsers') {
        // if (specificDevices.includes('All Mobile Devices')) return true;
        const deviceMatch = specificDevices.some((specificDevice) =>
          template.deviceAndBrowser
            .toLowerCase()
            .includes(specificDevice.toLowerCase()),
        );
        const browserMatch = specificBrowsers.some((specificBrowser) =>
          template.deviceAndBrowser
            .toLowerCase()
            .includes(specificBrowser.toLowerCase()),
        );
        //NOTE: WITHIN mobile/desktop and browsers -> only one of them needs to match, so this is an OR operation
        return deviceMatch || browserMatch;
      }
      return false;
    });

  //reset the unselected templates when the constraints change
  useEffect(() => {
    setUnselectedTemplates(new NodeSet());
  },[
    device,
    placement,
    specificDevices,
    specificBrowsers,
    genderOption,
    genders,
  ])

  useEffect(() => {
    //I believe this code compares two sets and sees if there is any difference
    if (
      constraintAdSets.concat(filteredTemplates).size !==
        constraintAdSets.size ||
      filteredTemplates.concat(constraintAdSets).size !== filteredTemplates.size
    )
      setConstraintAdSets(filteredTemplates);
  }, [filteredTemplates]);

  const removeSpecificTemplate = (template: AdSet) => {
    setUnselectedTemplates((prev) => new NodeSet([...prev, template.namedNode]));
  }

  return (
    <>
      {location.pathname === '/quicklaunch' && (
        <>
          <h2>
            {filteredTemplates.size} AdSet template
            {filteredTemplates.size !== 1 ? 's' : ''}
          </h2>
          <div className={style.labelSmall}>
            Click on a template to exclude it. Change parameters to reset the list.
          </div>
          <Suspense fallback={<Spinner />}>
            <TemplateList
              isLightTheme={isLight}
              of={filteredTemplates}
              refreshList={refreshList}
              onSelect={removeSpecificTemplate}
            />
          </Suspense>
        </>
      )}
    </>
  );
}
