import {ChangeEvent, useContext} from 'react';
import {DashboardContext} from '../contexts/Dashboard';
import './MasterURL.scss';
import {default as style} from './MasterURL.scss.json';

export function MasterURL() {
  const {masterUrl, setMasterUrl, landingPageName, setLandingPageName} =
    useContext(DashboardContext);

  const handleMasterURL = (e: ChangeEvent<HTMLInputElement>) => {
    const newURL = e.target.value;
    const isValid = e.target.checkValidity();
    const endsWithQM = newURL.endsWith('?') || newURL.includes('?');

    if (!isValid) {
      alert('Please enter a valid URL');

      // Refocus and don't trigger the onBlur event;
      // https://stackoverflow.com/a/9886348/7808223
      window.setTimeout(() => {
        e.target.focus();
      }, 0);
    } else if (newURL.length > 0) {
      if (!endsWithQM) {
        console.info("Adding '?' to end of URL");
      }
      setMasterUrl(newURL + (endsWithQM ? '' : '?'));
    } else {
      setMasterUrl(newURL);
    }
  };

  return (
    <div>
      <label htmlFor="master-url">
        <h3 title="The campaign name, adset name, and ad ID parameters will automatically be added to the end of this URL">
          Landing Page URL
        </h3>
      </label>
      <input
        data-cy="tracking-url"
        onBlur={handleMasterURL}
        onChange={(e) => setMasterUrl(e.target.value)}
        id="master-url"
        type="url"
        value={masterUrl}
        className={style.input}
      />
      <label htmlFor="master-url">
        <h3>Landing Page Name</h3>
      </label>
      <input
        data-cy="landing-page-name"
        onChange={(e) => setLandingPageName(e.target.value)}
        id="landing-page-name"
        type="text"
        value={landingPageName}
        className={style.input}
      />
    </div>
  );
}
