import {PageCard} from '../components/PageCard';
import {DefaultLayout} from '../layout/DefaultLayout';
import {ROUTES} from '../routes';
import './Admin.scss';
import * as styles from './Admin.scss.json';

export default function Admin() {
  const adminRoutes = Object.values(ROUTES).filter((r) =>
    r.path.startsWith('/admin/'),
  );
  return (
    <DefaultLayout>
      <h1>Admin</h1>
      <div className={styles.container}>
        {adminRoutes.map((route) => (
          <PageCard route={route.path} icon={route.icon} label={route.label} />
        ))}
      </div>
    </DefaultLayout>
  );
}
