import {DefaultLayout} from '../layout/DefaultLayout';
import {MagicLoginInputField} from 'lincd-passport/lib/components/MagicLoginInputField';

export default function Signin() {
  return (
    <DefaultLayout>
      <div>
        <h2>Sign In</h2>
        <MagicLoginInputField />
      </div>
    </DefaultLayout>
  );
}
