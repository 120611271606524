import {useContext, useEffect, useState} from 'react';
import {Delete} from 'titan-ads/lib/components/Delete';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {InputValues, PLATFORMS, Platform} from 'titan-ads/lib/types';
import {
  generateAdSetName,
  generateCampaignName,
  getInputsByPlatform,
  getPartialKeyByTitle,
} from 'titan-ads/lib/utils';
import {InputsContext} from '../contexts/AdInputs';
import {DashboardContext} from '../contexts/Dashboard';
import {ThemeContext} from '../contexts/Theme';
import {NO_MASTER_URL_MSG} from '../messages';
import '../pages/Dashboard.scss';
import globalStyle from '../pages/Dashboard.scss.json';
import AdColumn from './AdColumn';
import AdSetColumn from './AdSetColumn';
import CampaignColumn from './CampaignColumn';
import {CharacterLimit} from './CharacterLimit';
import './InputGroup.scss';
import style from './InputGroup.scss.json';
import {InternalVariables} from './InternalVariables';
import { Ad } from 'titan-ads/lib/shapes/TitanShapes';
import { ShapeSet } from 'lincd/lib/collections/ShapeSet';

interface InputGroup {
  id: number;
  savedAds: ShapeSet<Ad>;
}

export function InputGroup({id, savedAds}: InputGroup) {
  const {curAccount} = useContext(DashboardContext);

  const {
    inputGroups,
    handleAddGroup,
    handleEditAccount,
    handleEditPlatform,
    handleRemoveGroup,
  } = useContext(InputsContext);

  const {adSet, campaign, creatingAdSet, creatingCampaign, inputs, pasted} =
    inputGroups[id];

  const [curPlatform, setPlatform] = useState<Platform>(
    inputGroups[id].platform,
  );
  const [inputOptions, setInputs] = useState(getInputsByPlatform(curPlatform));

  const PRIORITIES = getInputsByPlatform(curPlatform)
    .map((a) => a.priority)
    .sort((a, b) => a - b);
  const MAX_PRIORITY = PRIORITIES[PRIORITIES.length - 1];
  const [priority, setPriority] = useState(
    pasted ? MAX_PRIORITY : PRIORITIES[0],
  );

  useEffect(() => {
    // Only necessary if each inputGroup can have its own account associated
    // with it
    handleEditAccount(id, curAccount);

    // Reset the priority if the account changes
    setPriority(pasted ? MAX_PRIORITY : PRIORITIES[0]);
  }, [curAccount]);

  const handleNextPriority = (
    curPriority?: number,
    setToCurPriority: boolean = false,
  ) => {
    if (pasted) {
      return;
    }

    let nextPriority = curPriority;

    // Automatically go to the next priority if none has been provided
    // Typically unsafe, but keeping it in unless I deem it too bug-worthy
    if (curPriority === undefined) {
      // Prevent array out of bounds errors
      if (priority == MAX_PRIORITY) {
        return;
      }

      const nextPriorityIndex = PRIORITIES.lastIndexOf(priority) + 1;
      nextPriority = PRIORITIES[nextPriorityIndex];
    } else if (!setToCurPriority) {
      const nextPriorityIndex = PRIORITIES.lastIndexOf(curPriority) + 1;
      nextPriority = PRIORITIES[nextPriorityIndex];
    }

    // Otherwise set the priority to whatever was provided...
    // This is useful for when a user changes a previous input, and the
    // inputs thereafter need to disappear again
    setPriority(nextPriority);
  };


  const onCopyAdsetName = () => {
    adSet.setLaunchDate();
    const name = adSet.setQuoraName();
    navigator.clipboard.writeText(
      name,
    );
    alert(`Copied "${name}" to clipboard`);
  }
  const copyCampaignName = () => {
    campaign.setLaunchDate();
    const name = campaign.setQuoraName(adSet);
    navigator.clipboard.writeText(
      name,
    );
    alert(`Copied "${name}" to clipboard`);
  }


  const {isLight} = useContext(ThemeContext);

  if (savedAds) {
    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <PopulateMasterURL
        ads={savedAds}
      />
    );
  }

  return (
    <>
      <div>
        <CharacterLimit groupId={id} />
      </div>
      <div
        className={
          isLight
            ? inputGroups[id].duplicate
              ? style['duplicate-row']
              : style.row
            : inputGroups[id].duplicate
            ? style['duplicate-row-dark']
            : style.rowDark
        }
      >
        <Delete
          onClick={() => {
            handleRemoveGroup(id);
          }}
        />
        {priority === MAX_PRIORITY && (
          <input
            aria-label="Duplicate"
            className={style.duplicateRow}
            onClick={() => {
              let newGroupId = 0;
              if (Object.keys(inputGroups).length > 0) {
                newGroupId =
                  Math.max(
                    ...Object.keys(inputGroups).map((x) => Number.parseInt(x)),
                  ) + 1;
              }
              const {inputs: groupInputs, campaign: groupCampaign} =
                inputGroups[id];
              handleAddGroup(
                newGroupId,
                curPlatform,
                {...groupInputs},
                groupCampaign,
              );
            }}
            type="button"
            title="Duplicate ad"
          />
        )}
        <div className={style['manual-input']}>
          <div>
            <label htmlFor="platform">Platform</label>
            <select
              disabled
              value={curPlatform}
              onChange={(e) => {
                const platform = e.target.value as Platform;

                const updatedDropdowns = getInputsByPlatform(platform);
                setInputs(updatedDropdowns);

                setPlatform(platform);

                // Required to ensure input arrays don't exceed their
                // maximum length
                handleEditPlatform(id, platform);
              }}
              name="platform"
              title="Only Quora is supported at the moment"
            >
              {PLATFORMS.map((p) => {
                return (
                  <option key={p} value={p}>
                    {p}
                  </option>
                );
              })}
            </select>
          </div>
          {priority >= 2 && (
            <div>
              <input
                className={globalStyle.buttons}
                onClick={copyCampaignName}
                type="button"
                value="Copy Campaign name"
              />
            </div>
          )}
          {priority >= 2 && (
            <div>
              <input
                className={globalStyle.buttons}
                onClick={onCopyAdsetName}
                type="button"
                value="Copy AdSet name"
              />
            </div>
          )}
        </div>

        <div className={style.subGrid}>
          <CampaignColumn
            groupId={id}
            handleNextPriority={handleNextPriority}
            initialCampaign={pasted ? campaign : undefined}
          />
          {priority > 0 && (
            <AdSetColumn
              groupId={id}
              handleNextPriority={handleNextPriority}
              initialCampaign={pasted ? campaign : undefined}
              initialAdSet={pasted ? adSet : undefined}
            />
          )}
          {priority > 1 && (
            <AdColumn
              groupId={id}
              handleNextPriority={handleNextPriority}
              priority={priority}
            />
          )}
        </div>
        {priority >= MAX_PRIORITY && (
          <>
            <h2>Internal reporting variables</h2>
            <InternalVariables
              creatingAdSet={creatingAdSet}
              creatingCampaign={creatingCampaign}
              groupId={id}
              handleNextPriority={handleNextPriority}
            />
            {/* <h2>Internal reporting variables</h2>
            <div className={style.subGrid}>
              {inputOptions.map((partial: AdInputPartial, i) => {
                if (!partial.internal) return <></>;

                const belongsToPreexistingEntity =
                  (partial.column === 'adset' && !creatingAdSet) ||
                  (partial.column === 'campaign' && !creatingCampaign);

                if (priority >= 3) {
                  return (
                    <AdInput
                      key={`${curPlatform}-${i}`}
                      disabled={belongsToPreexistingEntity}
                      className={style[partial.column]}
                      groupId={id}
                      handleNextPriority={handleNextPriority}
                      id={getPartialKey(partial)}
                      inputData={partial}
                      initialValue={
                        inputGroups[id].inputs[getPartialKey(partial)]
                      }
                    />
                  );
                }
              })}
            </div> */}
          </>
        )}
      </div>
    </>
  );
}

export function PopulateMasterURL({
  ads
}: {
  ads: ShapeSet<Ad>;
}) {
  const {isLight} = useContext(ThemeContext);

  return (
    <div>
      <h2>✅ Ad(s) created successfully</h2>
      {ads.map(ad =>
        <div key={ad.uri} className={style.successfulAd + ' ' + (isLight ? style.resultRow : style.resultRowDark)}>
          <div className={style.grid2}>
            <span>Tracking URL:</span>
            <a href={ad.fullLandingPageUrl}>{ad.fullLandingPageUrl}</a>
            <span>Ad Name:</span>
            <span>{ad.name}</span>
          </div>
          <button
            onClick={() => navigator.clipboard.writeText(ad.name)}
            aria-value="Copy Name"
            className={style.buttonCopy}
          >
            Copy Name
          </button>
          <button
            onClick={() => navigator.clipboard.writeText(ad.fullLandingPageUrl)}
            aria-value="Copy Tracking URL"
            className={style.buttonCopy}
          >
            Copy URL
          </button>
          <button
            onClick={() => window.open(`https://www.quora.com/ads/edit_ad?id=${ad.quoraId}`,'_blank')}
            aria-value="Open in Quora"
            className={style.buttonCopy}
          >
            Open in Quora Ad Manager
          </button>

        </div>)}
    </div>
  );
}
