import { localFetch } from "./localhost";
import {JSONParser} from 'lincd-jsonld/lib/utils/JSONParser';

export const saveAds = (payload: any,contextData) => {
    const body = JSON.stringify({ payload ,contextData});
    return localFetch("/save-ads", body).then(async (res) => {
      const body = await res.text();
      let json;
      try {
        json = await JSONParser.parse(body);
      }
      catch (e) {
        console.error('Failed to save ads', e);
        console.error('Response', body);
        throw Error('Failed to save ads - '+e.message );
      }
      return json.content;
    });
};
