import {Link} from 'react-router-dom';
import style from './Header.scss.json';
import './Header.scss';
import {ROUTES} from '../routes';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {IoSunnyOutline} from 'react-icons/io5';
import {ThemeContext} from '../contexts/Theme';
import {useContext} from 'react';
import React from 'react';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

export function Header() {
  const {userAccount} = useAuth();
  const {isLight, toggleTheme} = useContext(ThemeContext);

  return (
    <header className={isLight ? style.header : style.headerDark}>
      <img
        src={asset("/images/Titaninteractive.webp")}
        className={style.logo}
        alt="Titan Interactive"
      />
      <nav className={isLight ? style.menu : style.menuDark}>
        <IoSunnyOutline onClick={toggleTheme} />

        {Object.keys(ROUTES).map((key) => {
          const route = ROUTES[key];
          if (route.excludeFromMenu) return null;

          let userIsWhitelisted = false;

          const userEmailExists = userAccount?.email;
          const isWhitelistedRoute = route.whitelist;
          if (userEmailExists && isWhitelistedRoute) {
            userIsWhitelisted = route.whitelist.includes(userAccount.email);
          }

          const isValidForUser =
            !route.whitelist || (route.whitelist && userIsWhitelisted);

          return (
            <React.Fragment key={key}>
              {userEmailExists && isValidForUser && (
                <Link key={key} to={route.path}>
                  {route.label || key}
                </Link>
              )}
            </React.Fragment>
          );
        })}
      </nav>
    </header>
  );
}
