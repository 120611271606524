import {useContext, useEffect, useState} from 'react';
import {Question} from 'titan-ads/lib/shapes/Question';
import {DashboardContext} from '../../contexts/Dashboard';
import styles from '../InputGroup.scss.json';
import {SearchInput} from './SearchInput';
import {InputsContext} from '../../contexts/AdInputs';
import {GroupID} from '../../types';
import {TimeWindow} from './TimeWindow';
import {AdInputPartial} from 'titan-ads/lib/types';
import {getPartialKey} from 'titan-ads/lib/utils';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

interface QuestionsProps {
  groupId: GroupID;
  partial: AdInputPartial;
  includeTimeWindow?: boolean;

  disabled?: boolean;
}

export function Questions({
  disabled = false,
  groupId,
  partial,
  includeTimeWindow = false,
}: QuestionsProps) {
  const {curAccount} = useContext(DashboardContext);

  const {handleEditInput, inputGroups} = useContext(InputsContext);
  const {adSet, inputs} = inputGroups[groupId];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Question[]>([]);

  const chosen = adSet.questions;

  useEffect(() => {
    const newValue = chosen.map((question) => question.name).join(',');
    const oldValue = inputs[getPartialKey(partial)];
    if (newValue !== oldValue) handleEditInput(groupId, partial, newValue);
  }, [chosen]);

  const doSearch = (query: string) => {
    setIsLoading(true);

    curAccount.quoraAccount
      .getQuestions(query)
      .then((res) => {
        // Filter out questions that have already been chosen
        setResults(res.filter((r) => !chosen.find((c) => c.id === r.id)));
      })
      .finally(() => setIsLoading(false));
  };

  const onChooseResult = (result: Question) => {
    // Remove the chosen question from the results
    setResults((prev) => prev.filter((r) => r.id !== result.id));
    adSet.questions.add(result);
  };

  const deleteQuestion = (question: Question) => {
    adSet.questions.delete(question);
  };

  const processResult = (result: Question) => {
    const {name, weeklyViewsLb, weeklyViewsUb} = result;

    const viewsLb = weeklyViewsLb.toLocaleString('en-US');
    const viewsUb = weeklyViewsUb.toLocaleString('en-US');

    return `${name} (${viewsLb}-${viewsUb} weekly views)`;
  };

  return (
    <div className={`${styles.adset} ${styles.required}`}>
      {includeTimeWindow && <TimeWindow groupId={groupId} />}
      {chosen.map((question) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '20px',
            height: '15px',
          }}
        >
          <p>{question.name}</p>
          <img
            onClick={() => deleteQuestion(question)}
            src={asset("/images/cross.png")}
            style={{width: '15px', height: '15px', marginLeft: '5px'}}
          />
        </div>
      ))}
      <SearchInput
        disabled={disabled}
        doSearch={doSearch}
        isLoading={isLoading}
        label="Questions"
        onChooseResult={onChooseResult}
        processResult={processResult}
        results={results}
      />
    </div>
  );
}
