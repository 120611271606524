import {RequireAuth} from 'lincd-auth/lib/components/RequireAuth';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {createMagicLinkRoute} from 'lincd-passport/lib/utils/passportAuth';
import React, {Fragment, lazy, Suspense} from 'react';
import {IconType} from 'react-icons';
import {ImInsertTemplate} from 'react-icons/im';
import {
  FaArrowCircleRight,
  FaCogs,
  FaLink,
  FaLock,
  FaSearch,
  FaUserFriends,
} from 'react-icons/fa';
import {Route, Routes} from 'react-router-dom';
import {Spinner} from './components/Spinner';
import {DefaultLayout} from './layout/DefaultLayout';
import PageNotFound from './pages/PageNotFound';

//In React 18 you can use 'lazy' to import pages only when you need them.
//This will cause webpack to create multiple bundles, and the right bundles are automatically loaded
interface RouteObj {
  path: string;
  label: string;
  icon: IconType;
  component?: React.LazyExoticComponent<React.ComponentType<any>>;
  render?: () => JSX.Element;
  requireAuth?: boolean;
  whitelist?: string[];
  excludeFromMenu?: boolean;
}

export const ROUTES: {[key: string]: RouteObj} = {
  dashboard: {
    path: '/',
    icon: FaCogs,
    component: lazy(
      () => import('./pages/Dashboard' /* webpackPrefetch: true */),
    ),
    label: 'Ad Creation',
    requireAuth: true,
  },
  media: {
    path: '/media',
    icon: FaCogs,
    component: lazy(() => import('./pages/Media' /* webpackPrefetch: true */)),
    label: 'Media',
    requireAuth: true,
  },
  quickLaunch: {
    path: '/quicklaunch',
    icon: FaCogs,
    component: lazy(
      () => import('./pages/Quicklaunch' /* webpackPrefetch: true */),
    ),
    label: 'Quick Launch',
    requireAuth: true,
  },
  admin: {
    path: '/admin',
    icon: FaLock,
    component: lazy(() => import('./pages/Admin')),
    label: 'Admin',
    requireAuth: true,
    // whitelist: [
    //   'luke.mcilwee@titaninteractive.net',
    //   'taylor.holland@titaninteractive.net',
    //   'valentine@semantu.com',
    //   'deni@semantu.com',
    //   'carlen@semantu.com',
    //   'rene@semantu.com',
    // ],
  },
  accounts: {
    path: '/admin/accounts',
    label: 'Brand Accounts',
    icon: FaUserFriends,
    component: lazy(() => import('./pages/Accounts')),
    requireAuth: true,
    excludeFromMenu: true,
  },
  magiclink: {
    label: 'Magic Link',
    icon: FaLink,
    ...createMagicLinkRoute({path: '/'}, () => {
      return (
        <DefaultLayout>
          <Spinner />
        </DefaultLayout>
      );
    }),
    excludeFromMenu: true,
  },
  search: {
    path: '/admin/search',
    label: 'Search',
    icon: FaSearch,
    component: lazy(() => import('./pages/Search')),
    requireAuth: true,
    excludeFromMenu: true,
  },
  signin: {
    path: '/signin',
    icon: FaArrowCircleRight,
    component: lazy(() => import('./pages/Signin' /* webpackPrefetch: true */)),
    label: 'Sign In',
    excludeFromMenu: true,
  },
  templater: {
    path: '/admin/templates',
    icon: ImInsertTemplate,
    label: 'Templates',
    excludeFromMenu: true,
    component: lazy(() => import('./pages/Templates')),
    requireAuth: true,
  },
};

export default function AppRoutes() {
  const {userAccount} = useAuth();

  return (
    <Routes>
      {Object.keys(ROUTES).map((routeName) => {
        const route = ROUTES[routeName];
        const Component = route.component;

        //if a route is marked as requireAuth, wrap it in the RequireAuth component and pass the signinRoute
        const AuthGuard = route.requireAuth ? RequireAuth : React.Fragment;
        const authProps = route.requireAuth
          ? {signinRoute: ROUTES.signin.path}
          : {};

        // define a render function that determines what to render based on the component and route.render

        const renderRoute = () =>
          // if a Component is defined, render it using JSX syntax (<Component />)
          // if not, check if a route.render function is defined and call that render function if available.
          // if neither Component nor route.render is defined, return null
          Component ? <Component /> : route.render ? route.render() : null;

        let userIsWhitelisted = false;

        const userEmailExists = userAccount?.email;
        const isWhitelistedRoute = route.whitelist;
        if (userEmailExists && isWhitelistedRoute) {
          userIsWhitelisted = route.whitelist.includes(userAccount.email);
        }

        const isValidForUser =
          !route.whitelist || (route.whitelist && userIsWhitelisted);

        return (
          <Fragment key={route.path}>
            {isValidForUser && (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <AuthGuard {...authProps}>
                    <Suspense fallback={<Spinner />}>{renderRoute()}</Suspense>
                  </AuthGuard>
                }
              />
            )}
          </Fragment>
        );
      })}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
