import {createContext, useState} from 'react';

export const ThemeContext = createContext({isLight: true, toggleTheme: null});

export default function ContextProvider({children}) {
  const [theme, setTheme] = useState(
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light',
  );

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <ThemeContext.Provider value={{isLight: theme === 'light', toggleTheme}}>
      {children}
    </ThemeContext.Provider>
  );
}
