import {useContext, useEffect, useState} from 'react';
import {Audience} from 'titan-ads/lib/shapes/Audience';
import {DashboardContext} from '../../contexts/Dashboard';
import {SearchResults, searchClasses} from './SearchInput';

interface AudiencesProps {
  className?: string;
  disabled?: boolean;
  initialAudience?: Audience;
}

export function Audiences({
  className,
  disabled = false,
  initialAudience,
}: AudiencesProps) {
  const {curAccount} = useContext(DashboardContext);

  const [audiences, setAudiences] = useState<Audience[]>([]);
  const [chosenAudience, setChosenAudience] =
    useState<Audience>(initialAudience);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchAudiences = (useCache: boolean = true) => {
    setIsLoading(true);
    curAccount.quoraAccount
      .getAudiences(useCache)
      .then((res) => {
        setAudiences(res);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchAudiences();
  }, []);

  return (
    <div className={className}>
      <button onClick={() => fetchAudiences(false)}>Fetch audiences</button>
      <div className={searchClasses.container}>
        <label>Audience</label>
        <input
          className={searchClasses.searchbar}
          disabled={isLoading || disabled}
          value={chosenAudience?.name}
        />
        <span>{chosenAudience?.name}</span>
        <SearchResults
          isLoading={isLoading}
          onChooseResult={(a) => setChosenAudience(a)}
          processResult={(a: Audience) => a.name}
          results={audiences}
        />
      </div>
    </div>
  );
}
