import Fuse from 'fuse.js';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import React,{ChangeEvent, useContext, useEffect, useState} from 'react';
import {TemplateList as TL} from 'titan-ads/lib/components/TemplateList';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {ThemeContext} from '../contexts/Theme';
import '../pages/Dashboard.scss';
import './ManageTemplates.scss';
import * as styles from './ManageTemplates.scss.json';
import {TemplateDetails} from 'titan-ads/lib/components/TemplateDropdown';
// import style from './ManageTemplates.scss.json';
// import {Spinner} from './Spinner';

export function ManageTemplates({
  readonly,
  refreshList,
  templatesRes,
}: {
  readonly: boolean;
  refreshList: () => void;
  templatesRes: {read(): ShapeSet<AdSet>};
}) {
  const [exactSearch, setExactSearch] = useState<boolean>(false);
  const [fuse, setFuse] = useState<Fuse<AdSet>>();
  const [filteredTemplates, setFilteredTemplates] = useState<ShapeSet<AdSet>>();
  const [search, setSearch] = useState<string>('');
  const [selectedTemplate,setSelectedTemplate] = useState<AdSet>();
  const {isLight} = useContext(ThemeContext);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleToggleSearchOpt = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'exact-search-opt') {
      setExactSearch(e.target.checked ? true : false);
    }
  };

  const templates: ShapeSet<AdSet> = templatesRes.read();

  useEffect(() => {
    if (templates instanceof ShapeSet) {
      const newFuse = new Fuse(Array.from(templates), {
        keys: ['name'],
        threshold: exactSearch ? 0 : 0.6,
        ignoreLocation: exactSearch ? true : false,
      });

      setFuse(newFuse);
    }
  }, [templates, exactSearch]);

  useEffect(() => {
    if (search) {
      const searchResults = fuse.search(search).map((result) => result.item);
      const resultsShapeSet: ShapeSet<AdSet> = new ShapeSet(searchResults);
      setFilteredTemplates(resultsShapeSet);
    } else {
      setFilteredTemplates(templates);
    }
  }, [fuse, search]);

  const numOfTemplates = search ? filteredTemplates.size : templates.size;

  return (
    <>
      <p>
        <b>{numOfTemplates} </b>
        Template{numOfTemplates != 1 ? 's' : ''}
      </p>
      <label htmlFor="template-search">Search </label>
      <input
        id="template-search"
        placeholder="Template name..."
        type="search"
        value={search}
        onChange={handleSearch}
      />
      <div>
        <label htmlFor="exact-search-opt">Exact search?</label>
        <input
          id="exact-search-opt"
          onChange={handleToggleSearchOpt}
          type="checkbox"
          value={exactSearch ? 'true' : 'false'}
        />
      </div>
      <div style={{maxHeight: '40vh', overflow: 'auto'}}>
        <TL
          isLightTheme={isLight}
          of={search ? filteredTemplates : templates}
          readonly={readonly}
          refreshList={refreshList}
          onSelect={template => setSelectedTemplate(template)}
        />
      </div>
      {selectedTemplate && <div className={styles.selected}>
        <span style={{float:'right',cursor:'pointer',verticalAlign:'middle'}} onClick={() => setSelectedTemplate(null)}>
          ✖
        </span>
        <TemplateDetails of={selectedTemplate} />

      </div>}
    </>
  );
}
