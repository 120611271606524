import {QUORA_OPT_CTA, QuoraCTA} from 'titan-ads/lib/types';
import '../pages/Quicklaunch.scss';
import style from '../pages/Quicklaunch.scss.json';
import {useContext} from 'react';
import {ThemeContext} from '../contexts/Theme';

interface VariationProps {
  mediaType;
  headlines;
  subHeadlines;
  ctaOptions: QuoraCTA[];
  answerOnline;
  handleHeadlineChange;
  handleBodyTextChange;
  handleCtaOptionChange;
  handleAnswerChange;
  handleAddHeadline;
  handleAddBodyText;
  handleAddCtaOption;
  handleAddAnswer;
  handleRemoveHeadline;
  handleRemoveBodyText;
  handleRemoveCtaOption;
  handleRemoveAnswer;
}

export default function VariationComponent({
                                             mediaType,
                                             headlines,
                                             subHeadlines,
                                             ctaOptions,
                                             answerOnline,
                                             handleHeadlineChange,
                                             handleBodyTextChange,
                                             handleCtaOptionChange,
                                             handleAnswerChange,
                                             handleAddHeadline,
                                             handleAddBodyText,
                                             handleAddCtaOption,
                                             handleAddAnswer,
                                             handleRemoveHeadline,
                                             handleRemoveBodyText,
                                             handleRemoveCtaOption,
                                             handleRemoveAnswer,
                                           }: VariationProps) {
  const {isLight} = useContext(ThemeContext);
  return (
    <div className={style.variationContent}>
      {mediaType[0] !== 'promoted_answer' && (
        <div className={style.sectionLeft}>
          {/* Headline Inputs */}
          {headlines.map(({text,description}, index) => (
            <div key={index} className={style.formInput}>
              <label>Headline:</label>
              <div className={style.plusContent}>
                <input
                  value={text}
                  maxLength={65}
                  onChange={(e) => handleHeadlineChange(index,e.target.value)}
                />
                <button onClick={handleAddHeadline} className={style.addPlus}>
                  +
                </button>
                <button
                  onClick={() =>
                    index > 0 ? handleRemoveHeadline(index) : null
                  }
                  className={style.addPlus}
                >
                  -
                </button>
              </div>
              <label>Headline Description:</label>
              <div>
                <input
                  value={description}
                  maxLength={30}
                  onChange={(e) =>
                    handleHeadlineChange(index,e.target.value,true)
                  }
                />
              </div>
            </div>
          ))}
          {/* Body Text Inputs */}
          {subHeadlines.map(({text,description}, index) => (
            <div key={index} className={style.formInput}>
              <label>Sub Headline:</label>
              <div className={style.plusContent}>
                <textarea
                  className={isLight ? style.bodyInput : style.bodyInputDark}
                  rows={3}
                  value={text}
                  spellCheck={false}
                  maxLength={105}
                  onChange={(e) => handleBodyTextChange(index,e.target.value)}
                />
                <button onClick={handleAddBodyText} className={style.addPlus}>
                  +
                </button>
                <button
                  onClick={() =>
                    index > 0 ? handleRemoveBodyText(index) : null
                  }
                  className={style.addPlus}
                >
                  -
                </button>
              </div>
              <label>Sub Headline Description:</label>
              <div>
                <input
                  value={description}
                  maxLength={30}
                  onChange={(e) =>
                    handleBodyTextChange(index,e.target.value,true)
                  }
                />
              </div>
            </div>
          ))}

          {/* CTA Options Inputs */}
          {ctaOptions.map((ctaOption,index) => (
            <div key={index} className={style.formInput}>
              <label>Call to action:</label>
              <div className={style.plusContent}>
                <select
                  value={ctaOption}
                  onChange={(e) => handleCtaOptionChange(index, e.target.value)}
                >
                  {QUORA_OPT_CTA
                    // Filter out all CTAs that have already been chosen
                    .filter((option) => {
                      return (
                        !ctaOptions.includes(option) ||
                        option === ctaOptions[index]
                      );
                    })
                    .map((cta, index) => (
                      <option key={index}>{cta}</option>
                    ))}
                </select>
                <button onClick={handleAddCtaOption} className={style.addPlus}>
                  +
                </button>
                <button
                  onClick={() =>
                    index > 0 ? handleRemoveCtaOption(index) : null
                  }
                  className={style.addPlus}
                >
                  -
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className={style.sectionRight}>
        {mediaType[0] === 'promoted_answer' &&
          answerOnline.map((answer, index) => (
            <div key={index} className={style.formInput}>
              <label>Answer URL:</label>
              <div className={style.plusContent}>
                <input
                  value={answer}
                  onChange={(e) => handleAnswerChange(index, e.target.value)}
                />
                <button onClick={handleAddAnswer} className={style.addPlus}>
                  +
                </button>
                <button
                  onClick={() => (index > 0 ? handleRemoveAnswer(index) : null)}
                  className={style.addPlus}
                >
                  -
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
