import {createContext, Dispatch, PropsWithChildren, useReducer} from 'react';
import {
  GroupID,
  IAdInputsContext,
  InputGroup,
  InputGroups,
  ReducerAction,
} from '../types';
import {
  AdInputPartial,
  InputType,
  InputValues,
  Platform,
} from 'titan-ads/lib/types';
import {getInputsByPlatform, getPartialKey} from 'titan-ads/lib/utils';
import {Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {BrandAccount} from 'titan-ads/lib/shapes/TitanShapes';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';

/**
 * @param groups The context state
 * @returns At least two indexes if duplicates are present, otherwise an empty array
 */
function anyDuplicates(groups: object): string[] {
  const duplicates = {};

  Object.keys(groups).forEach((groupId) => {
    const {inputs, platform} = groups[groupId];

    const platformInputs = getInputsByPlatform(platform);
    const duplicateKeys = platformInputs
      .map((partial) => [getPartialKey(partial), partial.isDuplicateKey])
      .filter((res) => res[1])
      .map((x) => x[0]);

    const duplicateKeyArr = Object.entries(inputs)
      .filter(([key]) => duplicateKeys.includes(key))
      .map((x) => x[1]);

    // Check that all the inputs required to check for a unique ad
    // are actually filled, and none are left blank/empty
    if (duplicateKeyArr.some((e) => !e)) {
      return;
    }

    const duplicateKey = JSON.stringify(duplicateKeyArr);
    duplicates[duplicateKey] = duplicates[duplicateKey] || [];
    duplicates[duplicateKey].push(groupId);
  });

  const duplicateIndexes: string[] = [];

  Object.values(duplicates).forEach((indexArray: string[]) => {
    if (indexArray.length > 1) {
      duplicateIndexes.push(...indexArray);
    }
  });

  return duplicateIndexes;
}

function inputsReducer(groups: InputGroups, action: ReducerAction) {
  switch (action.type) {
    case 'added_group':
      console.log('handling action added_group');
      return {
        ...groups,
        [action.groupId]: {
          adSet: action.adSet,
          campaign: action.campaign,
          inputs: action.defaultInputs,
          platform: action.platform,
          duplicate: false,
          pasted: action.pasted,
        },
      };
    case 'added_input': {
      const {groupId, partial, value} = action;

      const groupPlatform = groups[groupId].platform;

      const numOfInputs = Object.keys(groups[groupId].inputs).length;
      const maxNumOfInputs = getInputsByPlatform(groupPlatform).length;

      if (numOfInputs == maxNumOfInputs) {
        // Return old state, i.e. don't add a new input
        return groups;
      }

      const newState = {...groups};

      const inputKey = getPartialKey(partial);
      newState[groupId].inputs[inputKey] = value;

      const duplicates = anyDuplicates(newState);
      Object.keys(newState).map((gId) => {
        if (duplicates.includes(gId)) {
          newState[gId].duplicate = true;
        } else {
          newState[gId].duplicate = false;
        }
      });

      return newState;
    }
    case 'edited_account': {
      const {groupId, account} = action;

      const newState = {...groups};
      newState[groupId].account = account;

      return newState;
    }
    case 'edited_ad_set': {
      const {groupId, adSet} = action;

      const newState = {...groups};
      newState[groupId].adSet = adSet;

      return newState;
    }
    case 'edited_campaign': {
      const {groupId, campaign} = action;

      const newState = {...groups};
      newState[groupId].campaign = campaign;

      return newState;
    }
    case 'edited_input': {
      const {groupId, partial, value} = action;

      const newState = {...groups};

      const inputKey = getPartialKey(partial);
      newState[groupId].inputs[inputKey] = value;

      const duplicates = anyDuplicates(newState);
      Object.keys(newState).map((gId) => {
        if (duplicates.includes(gId)) {
          newState[gId].duplicate = true;
        } else {
          newState[gId].duplicate = false;
        }
      });

      return newState;
    }
    case 'edited_platform': {
      const {groupId, platform} = action;

      const newState = {...groups};
      newState[groupId].platform = platform;
      newState[groupId].inputs = {};

      // check for duplicates

      return newState;
    }
    case 'marked_as_creating_campaign': {
      const newState = {...groups};

      newState[action.groupId].creatingCampaign = true;

      return newState;
    }
    case 'marked_as_creating_ad_set': {
      const newState = {...groups};

      newState[action.groupId].creatingAdSet = action.isCreatingAdSet;

      return newState;
    }
    case 'marked_as_duplicate': {
      const newState = {...groups};

      newState[action.groupId].duplicate = true;

      return newState;
    }
    case 'removed_group': {
      const newState = {...groups};

      delete newState[action.groupId];

      const duplicates = anyDuplicates(newState);
      Object.keys(newState).map((gId) => {
        if (duplicates.includes(gId)) {
          newState[gId].duplicate = true;
        } else {
          newState[gId].duplicate = false;
        }
      });

      return newState;
    }
    case 'removed_all_groups': {
      const newState = {};

      return newState;
    }
    default: {
      alert('Unknown action.  Check console.');
      console.error(action, groups);
      return groups;
    }
  }
}

export const InputsContext = createContext<IAdInputsContext | null>(null);

const initialInputValues = {};

export function ContextProvider({children}: PropsWithChildren) {
  //TODO: Warning, had to add as any. But without it there are typescript errors
  const [inputGroups, dispatch]: [InputGroups, Dispatch<ReducerAction>] =
    useReducer(inputsReducer, initialInputValues) as any;

  const handleAddGroup = (
    groupId: GroupID,
    platform: Platform,
    defaultInputs: InputValues = {},
    campaign?: Campaign,
    adSet?: AdSet,
  ) => {
    console.log('adding group');
    dispatch({
      type: 'added_group',
      platform,
      groupId,
      defaultInputs,
      pasted: Object.values(defaultInputs).filter((x) => x).length > 0,
      isCreatingAdSet: false,
      isCreatingCampaign: false,
      campaign,
      adSet,
    });
  };

  const handleAddInput = (
    groupId: GroupID,
    partial: AdInputPartial,
    value: string,
  ) => {
    console.log(`adding input [${value}]`);
    dispatch({
      type: 'added_input',
      groupId,
      value,
    });
  };

  const handleEditAccount = (groupId: GroupID, account: BrandAccount) => {
    console.log('Edited account');
    dispatch({
      type: 'edited_account',
      groupId,
      account,
    });
  };

  const handleEditAdSet = (groupId: GroupID, adSet?: AdSet) => {
    console.log('EDITING', adSet);
    if (!adSet) {
      const oldAdSet = inputGroups[groupId].adSet;
      if (!oldAdSet) return;

      console.log(
        `removed adset [#${groupId} ${oldAdSet.uri}]: [${oldAdSet.name}]`,
      );
    } else
      console.log(`edited adset [#${groupId} ${adSet.uri}]: [${adSet.name}]`);

    dispatch({
      type: 'edited_ad_set',
      groupId,
      adSet,
    });
  };

  const handleEditCampaign = (groupId: GroupID, campaign: Campaign) => {
    console.log(`edited campaign [${campaign.uri}]: [${campaign.name}]`);

    const {adSet} = inputGroups[groupId];

    if (adSet && adSet.namedNode.isTemporaryNode) {
      adSet.namedNode.remove();
    }

    dispatch({
      type: 'edited_campaign',
      groupId,
      campaign,
    });
  };

  const handleEditInput = (
    groupId: GroupID,
    partial: AdInputPartial,
    value: InputType,
  ) => {
    const inputKey = getPartialKey(partial);
    console.log(`edited input [${groupId}#${inputKey}]: [${value}]`);

    dispatch({
      type: 'edited_input',
      groupId,
      partial,
      value,
    });
  };

  const handleEditPlatform = (groupId: GroupID, platform: Platform) => {
    console.log('edited platform');
    dispatch({
      type: 'edited_platform',
      groupId,
      platform,
    });
  };

  // Typically only used after having saved the ads
  const handleMarkAsDuplicate = (groupId: GroupID) => {
    console.log(`Marking Group #${groupId} as duplicate`);
    dispatch({
      type: 'marked_as_duplicate',
      groupId,
    });
  };

  const handleRemoveAllGroups = () => {
    console.log('Deleting all groups');

    Object.values(inputGroups).forEach((group: InputGroup) => {
      const {adSet, campaign} = group;
      try {
        if (campaign.namedNode.isTemporaryNode) campaign.namedNode.remove();
      } catch (e) {
        console.info('Encountered a campaign that has already been removed');
      }

      try {
        if (adSet.namedNode.isTemporaryNode) adSet.namedNode.remove();
      } catch (e) {
        console.info('Encountered an adset that has already been removed');
      }
    });

    dispatch({
      type: 'removed_all_groups',
    });
  };

  const handleRemoveGroup = (groupId: GroupID) => {
    if (!inputGroups[groupId]) {
      console.info(`No group to remove (#${groupId})`);
      return;
    }

    /** Check if the input group has created a campaign;
     * If it has, tell the user they can't remove the input group
     */
    const {adSet, campaign, creatingAdSet, creatingCampaign} =
      inputGroups[groupId];

    // Check if the input group is creating an adset or campaign
    // which is being used in other places.  If it is in use elsewhere,
    // it would be unsafe to delete the group.
    let anyDependentGroups: boolean;
    const usingNewCampaign = campaign && campaign.namedNode.isTemporaryNode;
    const usingNewAdSet = adSet && adSet.namedNode.isTemporaryNode;
    if (usingNewCampaign) {
      anyDependentGroups = Object.entries(inputGroups).some(
        ([gId, group]) =>
          gId !== groupId.toString() &&
          !group.creatingCampaign &&
          campaign.equals(group.campaign),
      );
    }
    // We don't care to search for dependent adSets if there are already dependent
    // groups
    if (usingNewAdSet && !anyDependentGroups) {
      anyDependentGroups = Object.entries(inputGroups).some(
        ([gId, group]) =>
          gId !== groupId.toString() &&
          !group.creatingAdSet &&
          adSet.equals(group.adSet),
      );
    }

    const isDeletingCampaignSource = usingNewCampaign && creatingCampaign;
    const isDeletingLastInstanceOfNewCampaign =
      isDeletingCampaignSource && !anyDependentGroups;
    const campaignName = campaign?.name;
    if (isDeletingLastInstanceOfNewCampaign) {
      campaign.namedNode.remove();
    }

    const isDeletingAdSetSource = usingNewAdSet && creatingAdSet;
    const isDeletingLastInstanceOfNewAdSet =
      isDeletingAdSetSource && !anyDependentGroups;
    const adSetName = adSet?.name;
    if (isDeletingLastInstanceOfNewAdSet) {
      adSet.namedNode.remove();
    }

    const safeToDeleteByAdSet =
      !isDeletingAdSetSource || isDeletingLastInstanceOfNewAdSet;
    const safeToDeleteByCampaign =
      !isDeletingCampaignSource || isDeletingLastInstanceOfNewCampaign;

    const reasons = [];
    switch (true) {
      case !safeToDeleteByCampaign:
        reasons.push(
          `- Another ad is dependent on this newly created campaign (${campaignName})`,
        );
      case !safeToDeleteByAdSet:
        reasons.push(
          `- Another ad is dependent on this newly created adset (${adSetName})`,
        );
    }
    if (reasons.length > 0) {
      alert(
        'Unable to delete this ad for the following reasons:\n\n' +
          reasons.join('\n'),
      );
    }

    const safeToDelete = safeToDeleteByCampaign && safeToDeleteByAdSet;
    if (safeToDelete) {
      dispatch({
        type: 'removed_group',
        groupId,
      });
    }
  };

  const handleMarkAsCreatingCampaign = (groupId: GroupID) => {
    dispatch({
      type: 'marked_as_creating_campaign',
      groupId,
      isCreatingCampaign: true,
    });
  };

  const handleMarkAsCreatingAdSet = (
    groupId: GroupID,
    isCreatingAdSet = true,
  ) => {
    dispatch({
      type: 'marked_as_creating_ad_set',
      groupId,
      isCreatingAdSet,
    });
  };

  return (
    <InputsContext.Provider
      value={{
        handleAddGroup,
        handleAddInput,
        handleEditAccount,
        handleEditAdSet,
        handleEditCampaign,
        handleEditInput,
        handleEditPlatform,
        handleMarkAsDuplicate,
        handleMarkAsCreatingCampaign,
        handleMarkAsCreatingAdSet,
        handleRemoveAllGroups,
        handleRemoveGroup,
        inputGroups,
      }}
    >
      {children}
    </InputsContext.Provider>
  );
}
