import {Suspense, useMemo, useState} from 'react';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {ManageTemplates} from '../components/ManageTemplates';
import {Spinner} from '../components/Spinner';
import {TemplateGroup} from '../components/TemplateGroup';
import {DefaultLayout} from '../layout/DefaultLayout';
import style from '../components/ManageTemplates.scss.json';
import {useSuspense} from '../utils';
import './Dashboard.scss';
import dashboardStyles from './Dashboard.scss.json';

export default function Templates() {
  const [foo, setFoo] = useState<boolean>(false);

  const forceRender = () => setFoo(!foo);

  const templatesRes = useMemo(
    () => useSuspense(AdSet.loadAllTemplates()),
    [foo],
  );

  const SpinnerQuora = () => {
    return (
      <div className={style.spinner}>
        {/*<p>Connecting to Quora...</p>*/}
        <Spinner />
      </div>
    );
  };

  return (
    <DefaultLayout>
      <h1>QuickLaunch Templates</h1>
      <button className={dashboardStyles.buttons} onClick={forceRender}>
        Refresh
      </button>
      <Suspense fallback={<SpinnerQuora />}>
        <ManageTemplates
          readonly={false}
          refreshList={() => window.location.reload()}
          templatesRes={templatesRes}
        />
      </Suspense>
      {/*<Suspense fallback={<SpinnerQuora />}>*/}
        <TemplateGroup refreshList={forceRender} templatesRes={templatesRes} />
      {/*</Suspense>*/}
    </DefaultLayout>
  );
}
