import {useContext, useEffect, useState} from 'react';
import {InputsContext} from '../../contexts/AdInputs';
import {GroupID} from '../../types';
import {AdInputPartial} from 'titan-ads/lib/types';
import '../AdInput.scss';
import inputStyles from '../AdInput.scss.json';
import '../InputGroup.scss';
import styles from '../InputGroup.scss.json';
import {Answer} from 'titan-ads/lib/shapes/Answer';
import {TimeWindow} from './TimeWindow';

interface AnswersProps {
  groupId: GroupID;
  partial: AdInputPartial;
  allowMultiple?: boolean;
  includeTimeWindow?: boolean;
  handleSuccessfulValidation?: (answer: Answer) => void;
}

export function Answers({
  groupId,
  partial,
  allowMultiple = true,
  includeTimeWindow = false,
  handleSuccessfulValidation,
}: AnswersProps) {
  const [buttonText, setButtonText] = useState<string>(
    `${allowMultiple ? 'Add' : 'Validate'} Answer`,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidAnswer, setIsValidAnswer] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  const [refresher, setRefresher] = useState<boolean>(false);
  const forceRender = () => setRefresher(!refresher);

  const {handleEditInput, inputGroups} = useContext(InputsContext);
  const {adSet, account} = inputGroups[groupId];

  useEffect(() => {
    handleEditInput(
      groupId,
      partial,
      allowMultiple ? adSet.answers.map((a) => a.url).join(',') : url,
    );
  }, [isLoading]);

  const removeURL = (answer: Answer) => {
    adSet.answers.delete(answer);
    forceRender();
  };

  const validateURL = () => {
    setIsLoading(true);
    account.quoraAccount
      .validateAnswerURL(url)
      .then((res: Answer) => {
        if (!res) {
          setIsValidAnswer(false);
          return alert('Invalid answer URL');
        } else if (allowMultiple) {
          adSet.answers.add(res);
          setUrl('');
        }
        setButtonText('Validated!');
        setTimeout(() => {
          setIsValidAnswer(true);
          setButtonText(`${allowMultiple ? 'Add' : 'Validate'} Answer`);
        }, 1500);
        handleSuccessfulValidation?.(res);
      })
      .finally(() => setIsLoading(false));
  };

  const className = `${allowMultiple ? styles.adset : styles.ad} ${
    inputStyles.Dropdown
  } ${styles.required}`;

  return (
    <div className={className}>
      {includeTimeWindow && <TimeWindow groupId={groupId} />}

      {adSet.answers.map((answer) => (
        <div style={{cursor: 'pointer'}} onClick={() => removeURL(answer)}>
          {/* {answer.url} */}
        </div>
      ))}

      {allowMultiple && (
        <span>
          {adSet.answers.size} Answer{adSet.answers.size === 1 ? '' : 's'}
        </span>
      )}
      <label>{!allowMultiple && 'Promoted'} Answer URL</label>
      <input
        disabled={isLoading}
        type="url"
        onChange={(e) => {
          setUrl(e.target.value.trim());
          if (e.target.value.trim() !== url) setIsValidAnswer(false);
        }}
        value={url}
      />
      {!isValidAnswer && (
        <button
          disabled={!url || isLoading || buttonText === 'Validated!'}
          onClick={validateURL}
        >
          {isLoading ? 'Validating...' : buttonText}
        </button>
      )}
    </div>
  );
}
