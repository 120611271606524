import {ChangeEvent} from 'react';
import {Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {SuspenseStatus} from './types';

let timer;
export function debounce(func, timeout = 700) {
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), timeout);
  };
}

export function yyyyMMddToMMddyy(date: string) {
  const [year, month, day] = date.split('-');

  return `${month}/${day}/${year.slice(2)}`;
}

export function useSuspense(promise: Promise<any>) {
  let status: SuspenseStatus = 'pending';

  let result;
  const suspender = promise.then(
    (r) => {
      status = 'success';
      result = r;
    },
    (e) => {
      status = 'error';
      result = e;
    },
  );

  return {
    read() {
      switch (status) {
        case 'error':
        case 'pending':
          throw suspender;
        case 'success':
          return result;
      }
    },
  };
}
export type Quarter = 'Q1' | 'Q2' | 'Q3' | 'Q4';

export interface DMYQ {
  DAY: string;
  MONTH: string;
  QUARTER: Quarter;
  YEAR: string;
}

/**
 * Get the day, month, and year of a date.
 * @param date
 */
export function getDMYQ(date: Date): DMYQ {
  const YEAR = date.getFullYear().toString();
  const MONTH = zeroPad(date.getMonth() + 1, 2);
  const DAY = zeroPad(date.getDate(), 2);

  const QUARTER: Quarter = getQuarter(date.getMonth().toString());

  return {DAY, MONTH, QUARTER, YEAR};
}

/**
 * Being used for file creation, ensuring dates
 * are padded with a leading `0` to a certain length:
 *
 * ```
 * zeroPad(5, 2)  // returns "05"
 * zeroPad(12345, 3)  // returns "1234"
 * ```
 */
export function zeroPad(num: number, maxPad: number): string {
  return `${num}`.padStart(maxPad, '0');
}

export function getQuarter(month:string): Quarter {
  return `Q${Math.ceil(parseInt(month) / 3)}` as Quarter;
}

// export function generateAdName(
//   platform: Platform,
//   values: string[],
// ): [string[], string] {
//   const adNameIndex = getInputIndex(platform, 'Ad Name');

//   const adName = values.filter((_, i) => i !== adNameIndex).join(', ');
//   const newValues = values.map((v, i) => (i === adNameIndex ? adName : v));

//   return [newValues, adName];
// }

// Looks up existing campaigns, if one with the same name exists, then
// increment the unique identifier
export function generateUniqueCampaignTitle(
  campaigns: Campaign[],
  campaignName: string,
): string {
  const suffix: number = campaigns.filter((c) => {
    const startsWithSameCampaignName = c.name.startsWith(campaignName);

    // Need to do an additional check for special cases where an account
    // name might be equal to the beginning of a string, but have more to
    // it.  For example:
    //
    // alreadyExists = ["a b cdef 1", "a b c 1"]
    // toCreate = "a b c"
    //
    // We want to match cases where everything except the last number is
    // part of the campaign title
    if (startsWithSameCampaignName) {
      const unmatchedIsNumber = !Number.isNaN(
        c.name.replace(campaignName, '').trim(),
      );

      return unmatchedIsNumber;
    }
  }).length;

  return `${campaignName} ${suffix + 1}`;
}

/**
 * Retrieves the currently selected option's value of a given attribute on a
 * HTMLSelectElement
 * @param e The element event from which the attribute should be retrieved
 * @param attr The name of the attribute attached to the HTML element
 * @returns The value given to `attr` on the HTML element
 */
export function getAttribute(e: ChangeEvent<HTMLSelectElement>, attr: string) {
  return e.target[e.target.selectedIndex].getAttribute(attr);
}

/**
 * Unique-ify adset and campaign results
 */
export function dedupe(withDuplicates: any[] = [], callback): any {
  const seen = {};

  return withDuplicates.filter((item) => {
    const prop = callback(item);

    return seen.hasOwnProperty(prop) ? false : (seen[prop] = true);
  });
}

/**
 * AdSet prefixes are in the form "XY-aZ09", and we're interested in
 * extracting the "aZ09" part of the prefix
 * @param adSetPrefix
 * @returns
 */
export function extractIdFromAdSetPrefix(adSetPrefix: string) {
  return adSetPrefix.split('-')[1];
}
