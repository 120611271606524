import React,{useEffect,useState} from 'react';
import Media from '../pages/Media';
import '../pages/Quicklaunch.scss';
import style from '../pages/Quicklaunch.scss.json';
import {QuoraAdType,QuoraAdTypes} from 'titan-ads/lib/types';
import {Ad} from 'titan-ads/lib/shapes/TitanShapes';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';

interface MediaUploaderProps
{
  mediaType: QuoraAdType[];
  media;
  onMediaReady;
  isQuickLaunched?: boolean;
  handleSubmit;
  handleMediaOptionChange: (
    index: number,
    value: QuoraAdType,
    bizName: string,
  ) => void;
}

export default function MediaUploader({
                                        mediaType,
                                        media,
                                        isQuickLaunched,
                                        onMediaReady,
                                        handleSubmit,
                                        handleMediaOptionChange,
                                      }: MediaUploaderProps) {
  const [businessName,setBusinessName] = useState('');
  const [adType,setAdType] = useState<QuoraAdType>('image_ad');
  return (
    <div className={style.mediaUploader}>
      <div className={style.mediaLoad}>
        <div className={style.formInput}>
          <h2>Business Name</h2>
          <input
            type="text"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
        </div>
        <h2>Ad Type</h2>
        {mediaType.map((mediaOptions,index) => (
          <div key={index} className={style.formInput}>
            <div className={style.plusContent}></div>
            <select
              value={mediaType}
              onChange={(e) => {
                handleMediaOptionChange(
                  index,
                  e.target.value as QuoraAdType,
                  businessName,
                );
                setAdType(e.target.value as QuoraAdType);
              }}
            >
              <option value={QuoraAdTypes[0]}>Image Ad</option>
              <option value={QuoraAdTypes[1]}>Promote an answer</option>
              <option value={QuoraAdTypes[2]}>Text Ad</option>
              <option value={QuoraAdTypes[3]}>Video Ad</option>
            </select>
          </div>
        ))}
        {(adType === 'image_ad' || adType === 'video_ad') && (
          <>
            <Media
              onMediaReady={handleSubmit}
              mediaType={mediaType[0]}
              isQuickLaunched={true}
              isSelected={onMediaReady(media)}
            />
            <label className={style.labelSmall}>
              * to add a <strong>business logo</strong> as well as an image, select both a logo
              and an image in Browse Media and press Insert Media.
            </label>
            <div className={style.mediaSelected}>
              {media.length > 0 && (
                <>
                  {media.map(
                    (mediaItem,index) => {
                      return (
                        <div key={index}>
                          {/*capitalized usage info as header*/}
                          <h5>{mediaItem.data.usageInfo.charAt(0).toUpperCase() + mediaItem.data.usageInfo.slice(1)}:</h5>
                          <div className={style.mediaAdset}>
                            {/*preview for logo and image for now*/}
                            {(mediaItem.data.usageInfo === 'logo' || mediaItem.data.usageInfo === 'image') ?
                              <img src={mediaItem.data.contentUrl} className={style[mediaItem.data.usageInfo]} width={'150'} /> : mediaItem.data.contentUrl.split('/').pop()}
                          </div>
                        </div>
                      );
                    },
                  )}
                </>
              )}
            </div>
          </>)}
      </div>
    </div>
  );
}
