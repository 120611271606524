import {useContext, useEffect, useState} from 'react';
import '../pages/Quicklaunch.scss';
import style from '../pages/Quicklaunch.scss.json';
import MediaUploadGallery from './MediaGallery';
import {MediaProps} from '../pages/Media';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {InternalVariables} from './InternalVariables';
import {getInputsByPlatform, getPartialKey} from 'titan-ads/lib/utils';
import {InputValues} from 'titan-ads/lib/types';
import {InputsContext} from '../contexts/AdInputs';
import {Spinner} from './Spinner';
import {BrandAccount} from 'titan-ads/lib/shapes/TitanShapes';
import styles from './InputGroup.scss.json';
import './InputGroup.scss';
import {ThemeContext} from '../contexts/Theme';
import {packageName} from '../package';

// Define the type for additional props
interface AdditionalProps {
  onHandleSubmit: (data: any) => void;
  campaignData?: any;
}

interface AdSet {
  name: string;
  bidAmount: string;
  adDelivery: string;
  geo: string;
  deviceAndBrowser: string;
  includedLocations: string;
  launchDate: string;
  excludedLocations: string;
  placement: string;
  targetingType: string;
  targetsMales: string;
  targetsFemales: string;
  targetingDescription: string;
}

interface CampaignDataItem {
  name: string;
  trafficSource: string;
  adAccount: string;
  adSets: {value: AdSet}[]; // Array of adSets
  dailyBudget: string;
  lifetimeBudget: string;
  conversionObjective: string;
  advertiser: string;
  network: string;
  conversionEvent: string;
  offer: string;
  vertical: string;
  subVertical: string;
  brandProperty: string;
  logo: string;
  geo: string;
  businessName: string;
  trackingLink: string;
}

// Combine the existing props with the additional props
type MediObjectProps = MediaProps & AdditionalProps;
// Regular "Ad Creation" groups display groups of index 0 and greater
// and src/components/TemplateGroup.tsx uses GROUP_ID = -2
const GROUP_ID = -1;

export default function InternalReport({
  isSelected,
  onMediaReady,
  campaignData,
  cta,
  onHandleSubmit,
  source,
}: MediObjectProps) {
  const [galleryImage, setGalleryImage] = useState([]);
  const [browseMedia, setBrowseMedia] = useState(false);
  const [brand, setBrand] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    trafficSource: '',
    adAccount: '',
    adset: {
      name: '',
      bidAmount: '',
      adDelivery: '',
      geo: '',
      deviceAndBrowser: '',
      includedLocations: '',
      launchDate: '',
      excludedLocations: '',
      placement: '',
      targetingType: '',
      targetsMales: '',
      targetsFemales: '',
      targetingDescription: '',
    },
    dailyBudget: '',
    lifetimeBudget: '',
    brand: '',
    conversionObjective: '',
    advertiser: '',
    network: '',
    conversionEvent: '',
    offer: '',
    vertical: '',
    subVertical: '',
    brandProperty: '',
    logo: '',
    geo: '',
    businessName: '',
    trackingLink: '',
  });
  const inputOptions = getInputsByPlatform('quora');

  const {handleAddGroup, inputGroups} = useContext(InputsContext);

  useEffect(() => {
    const inputs: InputValues = {};

    inputOptions.forEach((partial) => {
      inputs[getPartialKey(partial)] = undefined;
    });

    handleAddGroup(GROUP_ID, 'quora', inputs);
  }, []);

  useEffect(() => {
    setGalleryImage(null);
    if (brand) {
      const sanitisedBrandName = brand.name.replace(/\s+/g, '_');
      const getAllImages = async () => {
        try {
          const images = await Server.call(
            packageName,
            'readImageFromDB',
            sanitisedBrandName,
          );
          if (images) {
            // Transform ImageObject[] to MediaGalleryAsset[]
            const galleryAssets = images.map((image) => ({data: image}));
            setGalleryImage(galleryAssets);
          }
        } catch (error) {
          setGalleryImage([]);
          console.error('Error reading images:', error);
        }
      };
      getAllImages();
    }
  }, [brand]);

  const handleInput = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    if (formData) {
      onHandleSubmit(formData);
    }
  }, []);

  useEffect(() => {
    if (source) {
      handleInput('logo', source);
    }
  }, [source]);

  useEffect(() => {
    if (campaignData && campaignData.campaign) {
      campaignData.campaign.forEach((campaignItem) => {
        // Create a copy of formData
        let updatedFormData = {...formData};

        // Set formData with values from the current campaignItem
        updatedFormData = {
          ...updatedFormData,
          name: campaignItem.name,
          trafficSource: campaignItem.trafficSource,
          adAccount: campaignItem.adAccount,
          dailyBudget: campaignItem.dailyBudget,
          lifetimeBudget: campaignItem.lifetimeBudget,
          conversionObjective: campaignItem.conversionObjective,
          advertiser: campaignItem.advertiser,
          network: campaignItem.network,
          conversionEvent: campaignItem.conversionEvent,
          offer: campaignItem.offer,
          vertical: campaignItem.vertical,
          subVertical: campaignItem.subVertical,
          brandProperty: campaignItem.brandProperty,
          logo: campaignItem.logo,
          brand: campaignItem.account?.name,
          geo: campaignItem.geo,
          businessName: campaignItem.businessName,
          trackingLink: campaignItem.trackingLink,
        };

        // Iterate over the adSets of the current campaignItem
        campaignItem.adSets.forEach((adSetItem) => {
          // Update formData with ad set properties
          updatedFormData = {
            ...updatedFormData,
            adset: {
              ...updatedFormData.adset, // Preserve previous adset properties
              name: adSetItem.name,
              bidAmount: adSetItem.bidAmount,
              adDelivery: adSetItem.adDelivery,
              geo: adSetItem.geo,
              deviceAndBrowser: adSetItem.deviceAndBrowser,
              includedLocations: adSetItem.includedLocations,
              launchDate: adSetItem.launchDate,
              excludedLocations: adSetItem.excludedLocations,
              placement: adSetItem.placement,
              targetingType: adSetItem.targetingType,
              targetsMales: adSetItem.targetsMales,
              targetsFemales: adSetItem.targetsFemales,
              targetingDescription: adSetItem.targetingDescription,
            },
          };
        });

        // Update the state with the updatedFormData
        setFormData(updatedFormData);
      });
    }
  }, [campaignData]);

  console.log('data', formData);

  if (!inputGroups[GROUP_ID]) return <Spinner />;
  const isLight = useContext(ThemeContext).isLight;
  return (
    <div className={`${style.initialReport} ${styles.rowDark}`}>
      <InternalVariables
        creatingAdSet={true}
        creatingCampaign={true}
        groupId={GROUP_ID}
      />
      {/* <div className={style.sectionLeft}>
        <div className={style.formInput}>
          <label>Traffic Source:</label>
          <input
            value={formData.trafficSource}
            onChange={(e) => handleInput('trafficSource', e.target.value)}
          />
        </div>
        <div className={style.formInput}>
          <label>Ad Account:</label>
          <input
            value={formData.adAccount}
            onChange={(e) => handleInput('adAccount', e.target.value)}
          />
        </div>
        <div className={style.formInput}>
          <label>Advertiser:</label>
          <input
            value={formData.advertiser}
            onChange={(e) => handleInput('advertiser', e.target.value)}
          />
        </div>
        <div className={style.formInput}>
          <label>Network (if any):</label>
          <input
            value={formData.network}
            onChange={(e) => handleInput('network', e.target.value)}
          />
        </div>
        <div className={style.formInput}>
          <label>Offer:</label>
          <input
            value={formData.offer}
            onChange={(e) => handleInput('offer', e.target.value)}
          />
        </div>
        <div className={style.formInput}>
          <label>Vertical:</label>
          <input
            value={formData.vertical}
            onChange={(e) => handleInput('vertical', e.target.value)}
          />
        </div>
      </div>
      <div className={style.sectionRight}>
        <div className={style.formInput}>
          <label>Sub Vertical:</label>
          <input
            value={formData.subVertical}
            onChange={(e) => handleInput('subVertical', e.target.value)}
          />
        </div>
        <div className={style.formInput}>
          <label>Brand / Property:</label>
          <input
            value={formData.brand}
            onChange={(e) => handleInput('brandProperty', e.target.value)}
          />
        </div>
        <div className={style.formInput}>
          <label>GEO:</label>
          <input
            value={formData.geo}
            onChange={(e) => handleInput('geo', e.target.value)}
          />
        </div>
        <div className={style.formInput}>
          <label>Business Name:</label>
          <input
            value={formData.name}
            onChange={(e) => handleInput('businessName', e.target.value)}
          />
        </div>
        <div className={style.formInput}>
          <label>Logo:</label>
          <div className={style.formInput}>
            <label>Brand</label>
        </div>

        <div className={style.formInput}>
          <label>Tracking Link:</label>
          <input
            value={formData.trackingLink}
            onChange={(e) => handleInput('trackingLink', e.target.value)}
          />
        </div>
      </div> */}
      {browseMedia && (
        <MediaUploadGallery
          galleryImages={galleryImage}
          brandAccount={brand.name}
          disableUploadMore={true}
          fileInputRef={null}
          isSelected={isSelected}
          browseMedia={(boolean) => setBrowseMedia(boolean)}
          onMediaReady={onMediaReady}
          isMediaSelected={isSelected}
        />
      )}
    </div>
  );
}
